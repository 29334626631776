import React from "react";

const TableTabs = ({ active, handleTabChange, tabs }) => {
    return (
        <div className="ms-3">
            {tabs.map((tab, i) => <span
                key={i}
                className={active === i ? "active-tab tab" : "tab"}
                onClick={() => handleTabChange(i)}
            >
                {tab}
            </span>)}

        </div>
    );
};

export default TableTabs;