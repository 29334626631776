import React, { useEffect, useState } from "react";
import "./distribution.css"
import { CREATE_DISTRIBUTION } from "../../assets/images.config";
import { useNavigate } from "react-router-dom";
import Default from "../default/Default";
import Button from "../../components/UI/buttons/Button";

const CreateDistribution = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('')
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setShow(true)
      setName(e.target.value)
    } else setShow(false);
  };

  return (
    <Default paths={[{ label: "Create New" }]} pathHeader="Distribution" pathLink="/distributions">
      <main className="">
        <img
          src={CREATE_DISTRIBUTION}
          className="createDistributionImg"
          alt=""
        />
        <div
          className={`d-flex flex-column align-items-center justify-content-center distributionContainer`}
        >
          <h3 className="createDistributionTitle">
            Give a friendly name for your Distribution.
          </h3>
          <div className="d-flex align-items-center justify-content-center">
            <form onSubmit={(event) => {
              event.preventDefault()
              localStorage.setItem('distributionData', JSON.stringify({name: name}))
              // navigate("/distributions/accounts")
              navigate("/distributions/reports")
            }}>
              <label className="distributionLabel">
                Distribution Name
              </label>
              <span className="required">*</span>
              <input
                type="text"
                name=""
                id=""
                required
                className={`distributionInput`}
                onChange={(e) => handleChange(e)}
              />
              {show && (
                <Button
                  type="submit"
                  className={`nextButton shadow btn-default`}
                >
                  NEXT
                </Button>
              )}
            </form>
            <button
              className={`cancelButton btn btn-outline-primary btn-previous`}
              onClick={() => navigate("/distributions")}
            >
              CANCEL
            </button>
          </div>
        </div>
      </main>
    </Default>
  );
};

export default CreateDistribution;
