import React from "react";
import {
  DISTRIBUTION_METHOD,
  EMAIL,
  EXPORT,
} from "../../assets/images.config";
import { Link, useNavigate } from "react-router-dom";
import Default from "../default/Default";

const DistributionMethods = () => {
  const navigate = useNavigate();

  return (
    <Default
      paths={[
        { label: "Create New", link: "/distributions/create-new" },
        { label: "Reports", link: "/distributions/reports" },
        { label: "Distribution Method" }
      ]}
      pathHeader="Distribution"
      pathLink="/distributions"
    >
      <main className="d-flex flex-column align-items-center justify-content-center">
        <h2 className="description">
          Please select the method in which you would like to distribute.
        </h2>
        <div
          className={`d-flex justify-content-center`}
        >
          <div className="me-150px">
            {/* <Link to={"/distributions/schedule"}> */}
            <img src={EXPORT} onClick={() => {
              var old = JSON.parse(localStorage.getItem('distributionData'))
              delete old.emailTo
              delete old.emailFrom
              delete old.emailCC
              delete old.emailSubject
              delete old.emailBody
              localStorage.setItem('distributionData', JSON.stringify({ ...old, method: 'Export' }))
              navigate("/distributions/schedule")
            }} className="distributeImage" alt="" />
            {/* </Link> */}
          </div>
          {/* <Link to={"/distributions/schedule-email"}> */}
          <img
            src={EMAIL}
            onClick={() => {
              var old = JSON.parse(localStorage.getItem('distributionData'))
              localStorage.setItem('distributionData', JSON.stringify({ ...old, method: 'Email' }))
            navigate("/distributions/schedule-email")
            }}
            className="distributeImage"
            alt=""
          />
          {/* </Link> */}
        </div>
        <img
          src={DISTRIBUTION_METHOD}
          className="distributeMethod"
          alt=""
        />
      </main>
      <button
        className="btn btn-outline-primary py-2 btn-previous bottom-fixed"
        onClick={() => {
          navigate("/distributions/reports")
        }}
      >
        PREVIOUS
      </button>
    </Default>
  );
};

export default DistributionMethods;
