import React from "react";

const TableDropdown = (props) => {
  const { headerName, className } = props;

  return (
    <div className={`dropdown ${className}`}>
      <a
        data-bs-toggle="dropdown"
        className="pointer fs-12px text-decoration-none dropdown-toggle dropdown-link"
      >
        {headerName}
      </a>

      {props.children}
      
    </div>
  );
};

export default TableDropdown;