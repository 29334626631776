import React from "react";
import Default from "../../pages/default/Default";

const ProgressBar = () => {

    return (
        <Default>
            <main className="d-flex flex-column justify-content-center align-items-center main-progress">
                <h3 className="progress-title">
                    Please wait while your package is being exported...
                </h3>
                <div className="progress wrapper shadow">
                    <div role="progressbar" className="progress-bar bar is-loading" />
                </div>
            </main>
        </Default>
    );
};

export default ProgressBar;
