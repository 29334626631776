import React, { forwardRef, Fragment } from "react";

const TableCheckbox = forwardRef(({ listItems = [], selectedItems = [], onChangeSelection = () => { }, ...rest }, ref) => {

  const renderCheckbox = (value) => {
    return (
      <label className="checkbox">
        <input
          checked={value == 'all' ? listItems.length == selectedItems.length : selectedItems.includes(value)}
          type="checkbox"
          onChange={(event) => {
            var isChecked = event.target.checked
            if (value == 'all') {
              onChangeSelection(isChecked ? [...listItems] : [])
              return;
            }
            var existIndex = selectedItems.indexOf(value)
            var clone = [...selectedItems]
            if (existIndex != -1 && !isChecked) {
              clone.splice(existIndex, 1)
            }
            if (existIndex == -1 && isChecked) clone.push(value)
            onChangeSelection(clone)
          }}
          ref={ref}
          {...rest}
        />
        <span className="checkmark"></span>
      </label>
    );
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        {renderCheckbox('all')}
        <label htmlFor="" className="ms-4">
          Select All
        </label>
      </div>
      <div className="overflow-auto max-h-150px">
        {listItems.map((item, i) => {
          return (
            <li className="dropdown-item py-2" key={i}>
              <div className="d-flex align-items-center">
                {renderCheckbox(item)}
                <label htmlFor="" className="ms-4">
                  {item}
                </label>
              </div>
            </li>
          );
        })}
      </div>
    </Fragment>
  );
});

export default TableCheckbox;
