import React from "react";
import ReviewComponent from "./reviewComponent";

const Review = () => {
    return (
        <ReviewComponent />
    );
};

export default Review;
