import React from "react";
import { useNavigate } from "react-router-dom";
import Default from "../../default/Default";
import "../distribution.css";
import ScheduleComponent from "./ScheduleComponent";

const ScheduleDistributionEmail = () => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
       
        navigate("/distributions/draft-email")
    }

    return (
        <Default
            paths={[
                { label: "Create New", link: "/distributions/create-new" },
                { label: "Reports", link: "/distributions/reports" },
                { label: "Distribution Method", link: "/distributions/methods" },
                { label: "Schedule Distribution" }
            ]}
            pathHeader="Distribution"
            pathLink="/distributions"
        >
            <ScheduleComponent
                buttonText="DRAFT EMAIL TEMPLATE"
                handleSubmit={handleSubmit}
            />
        </Default>
    );
};

export default ScheduleDistributionEmail;
