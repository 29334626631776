import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/UI/buttons/Button';
import { LOGO_NEW, LOGO_NEW_WITH_COLOR } from '../../assets/images.config';

import './login.css';
import QBRequest from '../../services/QBRequest';
import PostRequest from '../../services/PostRequest';
import { useNavigate } from 'react-router-dom';

import Swal from "sweetalert2";

const Login = () => {

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);


    const handleLoginWinIntuit = async () => {

      
        if(isLoading) return false;

        try {

            setIsLoading(true);
            const authorize = await QBRequest('/authorize');
            // console.log('authorize',authorize);
            window.location.href = authorize.oAuthRedirectUri;

        } catch (error) {
            setIsLoading(false) ;
        } 

    }


    const loginUsingRRButton = async (event) => {

        if(isLoading) return false;

        let formData = new FormData(event.target);
        event.preventDefault();


        try {

            setIsLoading(true);
            const authorize = await PostRequest('/user/login',formData);

            if(authorize.companyInfo){
                localStorage.setItem('CompanyName',authorize.companyInfo.CompanyName)
            } else {
                localStorage.setItem('CompanyName',authorize.name)
            }

            localStorage.setItem('CompanyEmail',authorize.email)
            localStorage.setItem('QRAuthToken', authorize.token);

            navigate('/distributions');

           
        } catch (error) {
            setIsLoading(false) ;

            Swal.fire({
                icon: "error",
                title: 'Error!',
                text: error,
              });
      
              
        } finally {
            setIsLoading(false);   
        }

    }

  return (
    <Container>
    <Row>
      <Col>

            <div className='h-100vh d-flex justify-content align-items-center'>

                <div className='m-auto p-3'>

                    <div className='text-center mb-5'>
                        <a href='https://rapidreports.app/'>
                            <img src={LOGO_NEW_WITH_COLOR} height={50} alt="" />
                        </a>
                    </div>

                    <div>
                    
                    <form onSubmit={(e) => loginUsingRRButton(e)} >

                        <div className="mb-3">
                            <div>
                                <label className="inputLabel">
                                    Email <span className="required ms-0">*</span>{" "}
                                </label>
                            </div>
                            <input
                                type="email"
                                name="email"
                                className="finalizeInput"
                                autoComplete="off"
                                required
                            />
                        </div>


                        <div className="mb-5">
                            <div>
                                <label className="inputLabel">
                                    Password <span className="required ms-0">*</span>{" "}
                                </label>
                            </div>
                            <input
                                type="password"
                                name="password"
                                className="finalizeInput"
                                autoComplete="off"
                                required
                            />
                        </div>


                        <button type='submit' className='btn login-button w-100 pe-4_2'>
                            {isLoading && <i className='text-white fa fa-spin fa-spinner'></i>}  Sign In
                        </button>
                        
                    </form>


                    <div className='text-center'> 
                        <label className='or'> OR </label>
                    </div>

                    {/* <button onClick={handleLoginWinIntuit}  id='intuuit-login' className='btn login-button inutuit-button w-100 pe-4_2'>
                        {isLoading && <i className='text-white fa fa-spin fa-spinner'></i>}  Sign in With Intuit
                    </button> */}

                    <div onClick={handleLoginWinIntuit}  className='login-with-intuit-button'  >

                    </div>

                    </div>

                </div>

            </div>

      </Col>
    </Row>
  </Container>
  )
}

export default Login