import axios from 'axios';

import $ from 'jquery';
import { API_URLS } from '../config/api.urls';



const API_URL = API_URLS.API_POST_URL;

const PostRequest = async (path, data = {},button = false) => {

    const token = localStorage.getItem('QRAuthToken')
    const headers = { 'x-qb-authorized': token,'Content-Type': 'application/json' };

    return new Promise((resolve, reject) => {

        var buttonText = ''

        if(button){
            
            var elem = document.getElementById(button);
            buttonText = elem.textContent || elem.innerText;;
            elem.innerHTML = `<i class="fa fa-spinner fa-spin"> </i> Loading...`;
            elem.disabled = true;
        }


        axios.post(`${API_URL}${path}`, data, { headers: headers, crossDomain: true })
        .then(function (response) {
            try {

                if (response.data.code == 401) {
                    window.location.href = '/app/login'
                }
              
                if (response.data.code == 409) {
                    window.location.href = '/app/subscription/subscribe'
                }

                if (response.data.code !== 200) {
                    throw new Error(response.data.data);
                }
                resolve(response.data.data);
            } catch (error) {
                reject(error.message);
            } finally {
                if (button) {
                    var elem = document.getElementById(button);
                    if(elem){
                        elem.innerHTML = buttonText;
                        elem.disabled = false;
                    }
                }
            }
        })
        .catch(function (error) {
            console.log('Error : ', error)
            reject(error.message);
            if (button) {
                var elem = document.getElementById(button);
                if(!elem) return
                elem.innerHTML = buttonText;
                elem.disabled = false;
            }
        });
        
    })

}

export default PostRequest;