import React from "react";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import { CONTACT_US } from "../../assets/images.config";
import Button from "../../components/UI/buttons/Button";
import Sizebox from "../../components/UI/Sizebox";

import Swal from "sweetalert2";

const ContactUs = () => {
  const handleContactForm = (e) => {
    let formData = new FormData(e.target);
    e.preventDefault();

    let data = {};

    formData.forEach((val, key) => {
      data[key] = val;
    });
    // console.log("data", data);

    Swal.fire({
      icon: "success",
      title: 'Contact Request',
      text: `Thank you for reaching out! We appreciate your message and will respond promptly. Your satisfaction is important to us.`,
    });

  };

  return (
    <Default pathHeader="Contact Us" pathLink="/contact-us">
      <main>
        {/* <PageTitle description="Magna ea cupidatat esse veniam et laboris pariatur." /> */}
        <PageTitle description="Let us know how we can help!" />

        <div>

          <Sizebox gap={100} />

          <div className="d-flex align-items-center justify-content-center">

            <form onSubmit={(e) => handleContactForm(e)}>
              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Your Name <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="finalizeInput"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              {/* EMAIL */}

              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Your Email Address <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="finalizeInput"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              {/* PHONE */}
              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel me-3_5">Your Phone Number</label>
                  <input
                    type="phone"
                    name="phoneNumber"
                    className="finalizeInput"
                    autoComplete="off"
                  />
                </div>
              </div>

              {/* SUBJECT */}
              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Subject <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="subject"
                    className="finalizeInput"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>


              {/* Message */}
              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Message <span className="required ms-0">*</span>{" "}
                  </label>
                  <textarea placeholder="" className="finalizeInput" rows={5}>

                  </textarea>
                </div>
              </div>

              <Button type="submit" className={`nextButton btn-default shadow`}>
                Contact Us
              </Button>
            </form>
          </div>
        </div>

        <img src={CONTACT_US} className="contactUsImage  opacity-50" alt="" />
      </main>
    </Default>
  );
};

export default ContactUs;
