import React from "react";
import ProfileImage from "../ProfileImage";
import "./table.css";

const AccountNameSelector = (props) => {

    const { accountName, companyName, onClick, className } = props;

    return (
        <div className={`d-flex align-items-center ${className}`} onClick={onClick}>
            <ProfileImage accountName={accountName} />
            <div className="d-flex flex-column" style={{ width: '59%' }}>
                <h5 className={`mb-0 accountName`}>{accountName}</h5>
                <p className={`mb-0 companyName`}>{companyName}</p>
            </div>
        </div>
    );
};

export default AccountNameSelector;
