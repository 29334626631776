import React from "react";

const CustomTable = ({ header, body, style = {} }) => {
    return (
        <div className="table-responsive" style={style}>

            <table className="table table-striped">
                <thead>
                    {header}
                </thead>
                <tbody>
                    {body}
                </tbody>
            </table>

        </div>
    );
};

export default CustomTable;