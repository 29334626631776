import React from "react";

const Dropdown = (props) => {
  const { className, buttonText } = props;
  return (
    <div className={`dropdown ${className}`}>
      <button
        className="btn btn-outline-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {buttonText}
      </button>
      <ul className="dropdown-menu custom-dropdown-menu">
        {props.children}
      </ul>
    </div>
  );
};

export default Dropdown;