import React, { useEffect } from 'react';
import QBRequest from '../../services/QBRequest';

const Auth = () => {

    useEffect(() => {

        ( async () => {
            
            try {
                
                const authorize = await QBRequest('/authorize');
                // console.log('authorize',authorize);
                window.location.href = authorize.oAuthRedirectUri;

            } catch (error) {

                console.log('error',error)
                
            }


        } )();

    },[])

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height : '100vh' }}>
        Authenticating....
    </div>
  )
}

export default Auth