import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URLS, MAIN_DOMAIN_URL } from "../../../config/api.urls";
import PostRequest from "../../../services/PostRequest";
import QBRequest from "../../../services/QBRequest";

const Integration = ( { profile = null } ) => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)


    const handleConnectQB = async () => {
        try {
            setIsLoading(true)
            const response = await QBRequest('/connect-with-qb');
            window.location.href = response.oAuthRedirectUri;;

        } catch (error) {
            
        } finally {
            setIsLoading(false);
        }
    }

    const handleDisconnect = async () => {

        try {
            setIsLoading(true)
            const response = await QBRequest('/disconnect-app');
            window.location.href = '/app/distributions';

        } catch (error) {
            
        } finally {
            setIsLoading(false);
        }

    }


  return (
    <div className="p-4">
      <div className="row">

        {/* IF ITS CONNECTED ALREADY */}
       {(profile && !profile?.isConnectedWithQB) && <div className="col-12">
            <h5> Manage QuickBooks Connection </h5>

            <p>
                For content “Connect your QuickBooks account here to access your financial reports and create distributions.”
            </p>
                <button onClick={handleConnectQB} className="quickbooks-button"> {isLoading && <i className="fa fa-spin fa-spinner"></i>} Connect to QuickBooks </button>
            </div>
        }



        {/* IF DISSCONNECCTED */}

        {profile && profile?.isConnectedWithQB &&  <div className="col-12">
                <h5> Manage QuickBooks Connection </h5>
                <p>
                    Your are connected to QuickBooks. <span className="disconnecqb" onClick={handleDisconnect} > Disconnect </span> {isLoading && <i className="fa fa-spin fa-spinner"></i>}
                </p>
            </div>
        }


      </div>
    </div>
  );
};

export default Integration;
