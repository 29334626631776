import React, { useEffect, useRef, useState } from "react";

import {
  CardElement,
  useElements,
  useStripe,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import { LOGO, PACKAGES_BG, SUBSCRIPTION_BG } from "../../assets/images.config";
import SubscriptionCard from "./SubscriptionCard";
import StripeCheckout from "react-stripe-checkout";
import moment from "moment";
import PostRequest from "../../services/PostRequest";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PRICING } from "../../config/pricing";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const stripePromise = loadStripe(
  "pk_live_51NAcNbHyZTsyzW9wgEIYXbushPzhbFlaQMpzSE4reeIwfNnXPpgJxQtSRRpYzNtDZt2xvjCwB8OCXQzZ8zrbbv1000LWpEJFPt"
); // starts with pk_

const PaymentModel = (props) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const paymentFormRef = useRef();

  const handleFormSubmitEvent = () => {
    paymentFormRef.current.dispatchEvent(
      new Event("submit", { bubbles: true, cancelable: true })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const confirmPayment = await stripe.confirmPayment({
      elements,
      redirect : 'if_required'
    });

    setIsLoading(false);

    if (confirmPayment?.error) {
      if (confirmPayment.error.type === "card_error" || confirmPayment.error.type === "validation_error") {
        setMessage(confirmPayment.error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    } else {
      //  SUCCESS

      // console.log("paymentIntent", confirmPayment);
      if (props.onPaymentComplete) {
        props.onPaymentComplete(confirmPayment.paymentIntent);
      }
    }

  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Subscription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="payment-element">
          <form ref={paymentFormRef} id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
          </form>
          {message && <div id="payment-message" className="text-danger">{message}</div>}
        </div>
        {/* <CardElement /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isLoading || !stripe || !elements}
          onClick={handleFormSubmitEvent}
        >
          {" "}
          {isLoading ? "Please Wait..." : `Pay $${props.amount}`}{" "}
        </Button>
        <Button onClick={props.onHide} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PricingTable = (props) => {
  const navigate = useNavigate();

  // const stripe = useStripe();
  // const elements = useElements();

  const { showFreePlan = false } = props;

  const [activePlan, setActivePlan] = useState("Monthly");
  const [pricing, setPricing] = useState(DEFAULT_PRICING);
  const [modalShow, setModalShow] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(false);
  const [selectedPlanName, setSelectedPlanName] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(false);

  const [currentSubscriptionData, setCurrentSubscriptionData] = useState(false);

  const handleOnPlanSelect = async (plan,planName) => {
    try {
      
      const {paymentIntent,subscription} = await PostRequest("/subscription/payment/init",{plan :planName});
      // console.log("paymentInitData", paymentIntent);
      setClientSecret(paymentIntent.client_secret);
      setModalShow(true);
      setSelectedPlanName(planName)
      setSelectedPlan(plan);
      setSubscriptionId(subscription)
    } catch (error) {
      console.log(error);
      setClientSecret(false);
    }
  };

  const handlePaymentComplete = async (paymentIntent) => {

    try {
      const paymentData = {
        planName: selectedPlanName,
        plan : activePlan,
        paymentID : paymentIntent.id,
        amount : paymentIntent.amount,
        currency : paymentIntent.currency,
        subscriptionId : subscriptionId 
      };
      const subscription = await PostRequest(
        "/subscription/create-payment",
        paymentData
      );

      Swal.fire({
        icon: "success",
        title: `$${subscription.amount.toFixed(2)} Received`,
        text: `Your subscription has been activated, it will be expired on ${moment(
          subscription.expiring
        ).format("LLLL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/settings");
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
    

    // try {
    //   const paymentData = {
    //     payment_method: paymentIntent.payment_method,
    //     plan : selectedPlanName
    //   };

    //   const subscription = await PostRequest(
    //     "/subscription/payment/recurring",
    //     paymentData
    //   );

    //   console.log('subscription',subscription)

    //   // Swal.fire({
    //   //   icon: "success",
    //   //   title: `$${subscription.amount.toFixed(2)} Received`,
    //   //   text: `Your subscription has been activated, it will be expired on ${moment(
    //   //     subscription.expiring
    //   //   ).format("LLLL")}`,
    //   //   footer: `<a target="__blank" href="${subscription.paymentReceipt}">Download Payment Recept</a>`,
    //   // }).then((result) => {
    //   //   if (result.isConfirmed) {
    //   //     navigate("/subscriptions");
    //   //   }
    //   // });
    // } catch (error) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Something went wrong!",
    //   });
    // }
  };

  const loadPlans = async () => {
    try {
      setIsLoading(true);

      const p = await PostRequest("/subscription/pricing", {});
      setPricing(p);
    } catch (error) {
      console.log("Error while loading pricing");
    } finally {
      setIsLoading(false);
    }
  };


  const loadCurrentSubscription = async () => {

    try {

      const currentSubscription = await PostRequest('/subscription/current');
      // console.log('currentSubscription',currentSubscription)
      setCurrentSubscriptionData(currentSubscription);


    } catch (error) {

      console.log('Error is',error)
      
    }
  }

  useEffect(() => {
    loadPlans();
    loadCurrentSubscription();
  }, []);

  if (isLoading) return <i className="fa fa-spin fa-spinner fa-2x"></i>;

  return (
    <div id="generic_price_table">
      <div className="row">
        {clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
            }}
          >
            <PaymentModel
              show={modalShow}
              amount={selectedPlan.priceMonthly}
              onPaymentComplete={handlePaymentComplete}
              onHide={() => setModalShow(false)}
            />
          </Elements>
        )}

        {showFreePlan && (
          <div className="col-md-4">
            <SubscriptionCard
              currentPlan={currentSubscriptionData}
              planName="FREE"
              price={
                activePlan == "Monthly"
                  ? pricing.FREE.priceMonthly
                  : pricing.FREE.priceYearly
              }
              type={"Forever"}
              features={pricing.FREE.features}
              isActive={false}
              isPaymentAlow={false}
              activePlan={activePlan}
              handlePaymentModelShow={() => handleOnPlanSelect(pricing.FREE,'FREE')}
              actionName="Start Now"
            />
          </div>
        )}

        <div className={showFreePlan ? "col-md-4" : "col-md-4 offset-md-2"}>
          <SubscriptionCard
            currentPlan={currentSubscriptionData}
            planName="BASIC"
            price={
              activePlan == "Monthly"
                ? pricing.BASIC.priceMonthly
                : pricing.BASIC.priceYearly
            }
            type={activePlan == "Monthly" ? "MONTH" : "YEAR"}
            features={pricing.BASIC.features}
            isActive={true}
            isPaymentAlow={true}
            activePlan={activePlan}
            handlePaymentModelShow={() => handleOnPlanSelect(pricing.BASIC,'BASIC')}
            actionName="Start Now"
          />
        </div>

        <div className="col-md-4">
          <SubscriptionCard
            currentPlan={currentSubscriptionData}
            planName="PREMIUM"
            price={
              activePlan == "Monthly"
                ? pricing.PREMIUM.priceMonthly
                : pricing.PREMIUM.priceYearly
            }
            type={activePlan == "Monthly" ? "MONTH" : "YEAR"}
            features={pricing.PREMIUM.features}
            isActive={false}
            isPaymentAlow={true}
            activePlan={activePlan}
            handlePaymentModelShow={() => handleOnPlanSelect(pricing.PREMIUM,'PREMIUM')}
            actionName="Start Now"
          />
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
