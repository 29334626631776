import axios from 'axios';
import { API_URLS } from '../config/api.urls';



const API_URL = API_URLS.API_QB_URL;


const QBRequest = async (path, data = {}, isMediaUpload = false, button = false) => {

    const token = localStorage.getItem('QRAuthToken')
    const headers = { 'x-qb-authorized': token,'Content-Type': 'application/json' };

    if (isMediaUpload) {
        headers['content-type'] = 'application/x-www-form-urlencoded'
    }

    var buttonText = ''

    if (button) {
        var elem = document.getElementById(button);

        if(elem){
            buttonText = elem.textContent || elem.innerText;;
            elem.innerHTML = `<i class="fa fa-spinner fa-spin"> </i> Loading`;
            elem.disabled = true;
        }

    }

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}${path}`, data, { headers: headers })
            .then(function (response) {
                try {
                    if (response.data.code !== 200) {
                        throw new Error(response.data.data);
                    }
                    resolve(response.data.data);
                } catch (error) {
                    reject(error.message);
                } finally {
                    if (button) {
                        var elem = document.getElementById(button);
                        if(elem){
                            elem.innerHTML = buttonText;
                            elem.disabled = false;
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log('Error : ', error)
                reject(error.message);
                if (button) {
                    var elem = document.getElementById(button);
                    if(!elem) return
                    elem.innerHTML = buttonText;
                    elem.disabled = false;
                }
            });
    })
}


export default QBRequest;