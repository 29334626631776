import React from "react";
import Default from "../../default/Default";
import "../distribution.css"
import FinalizeComponent from "./FinalizeComponent";
import { useNavigate } from "react-router-dom";

const Finalize = () => {
    const navigate = useNavigate();
    return (
        <Default
            paths={[
                { label: "Create New", link: "/distributions/create-new" },
                { label: "Reports", link: "/distributions/reports" },
                { label: "Distribution Method", link: "/distributions/methods" },
                { label: "Schedule Distribution", link: "/distributions/schedule" },
                { label: "Finalize" }
            ]}
            pathHeader="Distribution"
            pathLink="/distributions"
        >

            <FinalizeComponent
                handleSubmit={() => {
                    navigate("/distributions/review")
                }}
            />

        </Default>
    );
};

export default Finalize;
