import React, { useEffect, useRef, useState } from "react";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import RichTextEditor from 'react-rte';
import {
    DRAFT_BOTTOM,
    DRAFT_TOP,
} from "../../assets/images.config";
import Default from "../default/Default";
import PostRequest from "../../services/PostRequest";

const DraftEmailTemplate = () => {
    const [toEmail, setToEmail] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [cc, setCC] = useState("");
    const [bcc, setBCC] = useState("");
    const [subjectLine, setSubjectLine] = useState("");
    const [body, setBody] = useState(RichTextEditor.createEmptyValue());

    const navigate = useNavigate();
    const editorRef = useRef()


    const getProfile = async () => {

       try {
        const profile = await PostRequest('/profile',{});
        setFromEmail(profile?.settings?.mailFromEmail)

       } catch (error) {
        
       }
        
    
      }


    const handleSubmit = (e) => {
        let formData = new FormData(e.target);
        e.preventDefault();

        let data = {};

        formData.forEach((val, key) => {
            data[key] = val;
        });
        
        var old = JSON.parse(localStorage.getItem('distributionData'))
        localStorage.setItem('distributionData', JSON.stringify({
            ...old,
            emailTo: data.toEmail,
            emailFrom: data.fromEmail,
            emailCC: data.cc,
            emailBCC: data.bcc,
            emailSubject: data.subjectLine,
            emailBody: body.toString('html'),
        }))
        navigate("/distributions/finalize-email")
    }

    useEffect(() =>{
        getProfile();
    },[])

    return (
        <Default
            paths={[
                { label: "Create New", link: "/distributions/create-new" },
                { label: "Reports", link: "/distributions/reports" },
                { label: "Distribution Method", link: "/distributions/methods" },
                { label: "Schedule Distribution", link: "/distributions/schedule" },
                { label: "Draft Email Template" }
            ]}
            pathHeader="Distribution"
            pathLink="/distributions"
        >
            <main
                className={`d-flex flex-column align-items-center justify-content-center draftContainer`}
            >
                <h1 className="heading">Draft your Email Template</h1>
                <form onSubmit={handleSubmit} style={{ width: "60%", paddingBottom:50 }}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="" className="draft-label">
                                To : <span className="required">*</span>
                            </label>
                            <div className="position-relative">
                                <FiMail size={15} className="icon" />
                                <input
                                    type="email"
                                    name="toEmail"
                                    value={toEmail}
                                    id=""
                                    className="emailInput"
                                    onChange={(e) => setToEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="" className="draft-label">
                                From : <span className="required">*</span>
                            </label>
                            <div className="position-relative text-end">
                                <FiMail size={15} className="icon" />
                                <input
                                    type="email"
                                    name="fromEmail"
                                    value={fromEmail}
                                    id=""
                                    className="emailInput"
                                    onChange={(e) => setFromEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column pb-2_5">
                        <label htmlFor="" className="draft-label">
                            CC : 
                        </label>
                        <input
                            type="email"
                            name="cc"
                            value={cc}
                            className="draft-input"
                            onChange={(e) => setCC(e.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-column pb-2_5">
                        <label htmlFor="" className="draft-label">
                            BCC : 
                        </label>
                        <input
                            type="email"
                            name="bcc"
                            value={bcc}
                            className="draft-input"
                            onChange={(e) => setBCC(e.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-column pb-2_5">
                        <label htmlFor="" className="draft-label">
                            Subject Line : <span className="required">*</span>
                        </label>

                        <input
                            type="text"
                            name="subjectLine"
                            value={subjectLine}
                            className="draft-input"
                            onChange={(e) => setSubjectLine(e.target.value)}
                            required
                        />
                    </div>
                    <div className="d-flex flex-column pb-2_5">
                        <label htmlFor="" className="draft-label">
                            Body : <span className="required">*</span>
                        </label>

                        <div>
                            <RichTextEditor
                                ref={editorRef}
                                value={body}
                                onChange={(v) => {
                                    setBody(v)
                                }}
                                editorStyle={{
                                    height: 170,
                                }}
                            />
                        </div>
                    </div>
                    {toEmail && fromEmail && subjectLine && body.getEditorState().getCurrentContent().hasText() && (
                        <button
                            type="submit"
                            className={`nextButton btn-default border-0 shadow px-0`}
                        // onClick={() => {
                        //     navigate("/distributions/finalize-email")
                        // }}
                        >
                            FINALIZE
                        </button>
                    )}
                </form>
            </main>
            <img src={DRAFT_BOTTOM} className="bottomLeft" alt="" />
            <img src={DRAFT_TOP} className="topRight" alt="" />
            <button
                className={`btn btn-outline-primary btn-previous cancelButton`}
                onClick={() => navigate("/distributions/schedule-email")}
            >
                PREVIOUS
            </button>
        </Default>
    );
};

export default DraftEmailTemplate;