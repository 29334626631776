import React, { useEffect, useState } from "react";
import { ACCOUNT_BG } from "../../assets/images.config";
import Divider from "../../components/UI/Divider";
import Dropdown from "../../components/UI//DropDown";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/UI/SearchInput";
import PostRequest from "../../services/PostRequest";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import CustomTable from "../../components/UI/table/CustomTable";
import TableDropdown from "../../components/UI/table/TableDropDown";
import TableCheckbox from "../../components/UI/table/TableCheckBox";
import Checkbox from "../../components/UI/CheckBox";
import AccountNameSelector from "../../components/UI/table/AccountNameSelector";
import Pagination from "../../components/UI/table/Pagination";



const Accounts = () => {

    const [activeElement, setActiveElement] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleActiveElement = (id) => {
        setActiveElement(id);
    };

    const loadAccounts = async () => {

        try {
            setLoading(true)
            const accountsData = await PostRequest('/accounts', {});
            // console.log('accountsData', accountsData);
            setAccounts(accountsData)
        } catch (error) {
            console.log('error', error);

            if (error == 'Please login your account.') {
                window.location.href = '/app/auth';
            }

        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        loadAccounts();
    }, [])

    return (
        <Default
            paths={[{ label: "Create New", link: "/distributions/create-new" }, { label: "Accounts" }]}
            pathHeader="Distribution"
            pathLink="/distributions"
        >
            <main>

                <PageTitle
                    image={ACCOUNT_BG}
                    description="Select the account you would like to create your new Distribution for."
                />

                <div className="tableContainer shadow">
                    <div className="d-flex justify-content-end ms-auto me-3_5 pt-4 pb-3">
                        <SearchInput placeholder="Search Accounts" />
                        <Dropdown className="px-3" buttonText="Filters">
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                        </Dropdown>
                        <Dropdown buttonText="Actions">
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                        </Dropdown>
                    </div>
                    <div className="ms-3">
                        <span
                            className={activeElement === 0 ? "active-tab tab" : "tab"}
                            onClick={() => handleActiveElement(0)}
                        >
                            All Accounts
                        </span>
                        <span
                            className={activeElement === 1 ? "active-tab tab" : "tab"}
                            onClick={() => handleActiveElement(1)}
                        >
                            Recent Accounts
                        </span>
                        <span
                            className={activeElement === 2 ? "active-tab tab" : "tab"}
                            onClick={() => handleActiveElement(2)}
                        >
                            Scheduled Accounts
                        </span>
                    </div>

                    <Divider />

                    {activeElement === 0 && (

                        <React.Fragment>


                            {!loading && <>
                                <CustomTable
                                    style={{ height: "calc(100vh - 430px)" }}
                                    header={<tr>
                                        <th className="sortable">Account Name</th>
                                        <th className="sortable">Primary Contact Name</th>
                                        <th className="sortable">Primary Contact Email</th>
                                        <th className="sortable">Primary Contact Phone</th>
                                        <th className="sortable text-center">Scheduled Distributions</th>
                                        <th className="text-center">Actions</th>
                                    </tr>}
                                    body={accounts.map((row, i) => {
                                        return <tr>
                                            <td className="w-25">
                                                <AccountNameSelector
                                                    accountName={row.Name}
                                                    className="pointer"
                                                    companyName={row.FullyQualifiedName}
                                                    onClick={() => {
                                                        var old = JSON.parse(localStorage.getItem('distributionData'))
                                                        localStorage.setItem('distributionData', JSON.stringify({...old, account: row}))
                                                        navigate("/distributions/reports")
                                                    }}
                                                />
                                            </td>
                                            <td className="w-25">{row.FullyQualifiedName}</td>
                                            <td className="w-20">{row.FullyQualifiedName.toLowerCase().split(" ")[0]}.gmail.com</td>
                                            <td className="w-20">{row.Name}</td>
                                            <td className="w-10 text-center">32</td>
                                            <td className="w-10 text-center">
                                                <img src={require('../../assets/images/distributions/edit.png')} className="pointer" height={20} alt="" />
                                            </td>

                                        </tr>
                                    })}
                                />
                                <Pagination />
                            </>}

                            {loading && <div className="d-flex align-items-center justify-content-center mb-3 mt-3">
                                <span> <i className="fa fa-spin fa-spinner fa-2x "></i> </span>
                            </div>
                            }

                        </React.Fragment>


                    )}
                </div>
            </main>
            <button
                className="btn btn-outline-primary py-2 btn-previous"
                onClick={() => navigate("/distributions/create-new")}
            >
                PREVIOUS
            </button>
        </Default>
    );
};

export default Accounts;