import axios from 'axios';

import $ from 'jquery';
import { API_URLS } from '../config/api.urls';



const API_URL = API_URLS.API_POST_URL;

const DownloadRequest = async (downloadURL,name,button = false) => {
    
    const token = localStorage.getItem('QRAuthToken')
    const headers = { 'x-qb-authorized': token,'Content-Type': 'application/json' };

    return new Promise((resolve, reject) => {

        var buttonText = ''

        if(button){
            
            var elem = document.getElementById(button);
            buttonText = elem.textContent || elem.innerText;;
            elem.innerHTML = `<i class="fa fa-spinner fa-spin"> </i> Loading...`;
            elem.disabled = true;
        }


        axios.get(`${API_URL}${downloadURL}`,{responseType : 'blob',headers : headers})
        .then(function (response) {
            try {

                if (response?.data?.code == 401) {
                    window.location.href = '/app/login'
                }
              
                if (response?.data?.code == 409) {
                    window.location.href = '/app/subscription/subscribe'
                }

                const href = URL.createObjectURL(response.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                resolve('OK');

                

            } catch (error) {
                
                reject(error.message);

            } finally {
                if (button) {
                    var elem = document.getElementById(button);
                    if(elem){
                        elem.innerHTML = buttonText;
                        elem.disabled = false;
                    }
                }
            }
        })
        .catch(function (error) {
            console.log('Error : ', error)
            reject(error.message);
            if (button) {
                var elem = document.getElementById(button);
                if(!elem) return
                elem.innerHTML = buttonText;
                elem.disabled = false;
            }
        });
        
    })

}

export default DownloadRequest;

