// LOGO
export const LOGO = require('./images/logo.png');
export const LOGO_NEW = require('./images/RR New Logo.png');
export const LOGO_NEW_WITH_COLOR = require('./images/logo_color.png');

// SIDEBAR

// Hamburger
export const HAMBURGER = require('./images/sidebar/HAMBURGER.png');

// NAVIGATION ICONS
export const DASHBOARD = require('./images/sidebar/dashboard.png')
export const ACCOUNTS = require('./images/sidebar/accounts.png')
export const DISTRIBUTIONS = require('./images/sidebar/distributions.png')
export const PACKAGES = require('./images/sidebar/packages.png')
export const CALENDER = require('./images/sidebar/calender.png')
export const ANALYTICS = require('./images/sidebar/analytics.png')
export const ADMINISTRATION = require('./images/sidebar/user.png')
export const SETTINGS = require('./images/sidebar/settings.png')
export const APERTURE = require('./images/sidebar/aperture.png')
export const SUPPORT = require('./images/sidebar/support.png')
export const CREDIT_CARD = require('./images/sidebar/credit-card.png')

export const CHEVRON = require('./images/sidebar/CHEVRON.png')

// BOTTOM-LEFT NAVIGATION
export const HOME = require('./images/sidebar/home.png')
export const BRD = require('./images/sidebar/brd.png')
export const HELP = require('./images/sidebar/help.png')

// HEADER
export const SEARCH = require('./images/header/search.png')
export const NOTIFICATION = require('./images/header/notification.png')
export const PROFILE = require('./images/header/profile-icon.png')

// DISTRIBUTIONS
export const DISTRIBUTION_BG = require('./images/distributions/bg.png')
export const CREATE_DISTRIBUTION = require('./images/distributions/create-distribution.png')
export const ACCOUNT_BG = require('./images/distributions/accounts.png')
export const REPORT_BG = require('./images/distributions/reports.png')

export const EXPORT = require('./images/distributions/export.png')
export const EMAIL = require('./images/distributions/email.png')
export const DISTRIBUTION_METHOD = require('./images/distributions/distribution-method.png')
export const EMAIL_PER_PACKAGE = require('./images/distributions/email-per-package.png')
export const EMAIL_PER_REPORT = require('./images/distributions/email-per-report.png')
export const DESCOPED_BG = require('./images/distributions/descoped-bg.png')
export const SCHEDULE_DISTRIBUTION = require('./images/distributions/schedule-bg.png')
export const FINALIZE = require('./images/distributions/finalize.png')
export const REVIEW = require('./images/distributions/review.png')
export const DRAFT_BOTTOM = require('./images/distributions/draft-bottom.png')
export const DRAFT_TOP = require('./images/distributions/draft-top.png')

export const PACKAGES_BG = require('./images/packages-bg.png')
export const FAQ_HEADER = require('./images/FAQ.png')
export const SETTINGS_HEADER = require('./images/SETTINGS.png')
export const CONTACT_US = require('./images/CONTACT_US.png')

export const SUBSCRIPTION_BG = require('./images/subscription.png')

export const SUCCESS_EXPORT = require('./images/distributions/success-export.png')
export const SUCCESS_EMAIL = require('./images/distributions/success-email.png')

export const FAILURE = require('./images/distributions/failure.png')