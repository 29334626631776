import React, { useEffect, useState } from "react";
import { SEARCH } from "../../assets/images.config";

const SearchInput = (props) => {
  const { placeholder = "", isTextarea = false } = props;

  const [timerId, setTimerId] = useState(null);
  const [search, setSearch] = useState('')

  const handleChange = (event) => {
    clearTimeout(timerId);
    const newTimerId = setTimeout(() => {
      if (props.onChange) props.onChange(event.target.value)
    }, 500);
    setTimerId(newTimerId);
  }

  useEffect(() => {
    if (props.value) setSearch(props.value)
  }, [props.value])

  return (
    <div className={`position-relative ${isTextarea ? 'mt-1' : ''}`}>
      <img src={SEARCH} alt="" className="searchIcon" />
      {isTextarea ? (
        <textarea
          cols="23"
          rows="3"
          placeholder={placeholder}
          className="pt-2 ps-4_2 searchInput"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onKeyUp={handleChange}
          style={{ resize: 'none' }}
        ></textarea>
      ) : (
        <input
          type="text"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onKeyUp={handleChange}
          placeholder={placeholder}
          className="py-2 ps-4_2 searchInput"
        />
      )}
    </div>
  );
};

export default SearchInput;