import React, { useEffect, useState } from "react";
import Default from "../default/Default";
import { PACKAGES_BG } from "../../assets/images.config";
import CustomTable from "../../components/UI/table/CustomTable";
import PageTitle from "../../components/UI/PageTitle";
import PostRequest from "../../services/PostRequest";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const SubscriptionsList = () => {

  const navigate = useNavigate();
  const [subscriptions,setSubscriptions] = useState([]);


  const loadSubscriptions = async() => {

    try {
        
        const _s = await PostRequest('/subscriptions');
        // console.log(_s);
        setSubscriptions(_s)

    } catch (error) {
        console.log('Error',error);
    }

  }

  const addNewSubscriptions = () => {
    navigate('/subscription/renew')
  }

    useEffect(() => {

        loadSubscriptions();

    },[])


  return (
    <React.Fragment>
       <div className="d-flex justify-content-end ms-auto me-3_5 pt-4 pb-3">
           <button onClick={addNewSubscriptions} className="btn btn-primary btn-sm"> Manage Subscription </button>
        </div>
        
          <CustomTable
            header={
              <tr>
                <th>Payment Date</th>
                <th>Subscription Start</th>
                <th>Subscription End</th>
                <th>Plan</th>
                <th>Amount</th>
                <th>Payment Status</th>
                <th>Receipt</th>    
              </tr>
            }
            body={
                subscriptions.map((subscription,i) => {
                    return (<tr key={i}>

                        <td>{moment(subscription.created_at).format('LLL')}</td>
                        <td>{moment(subscription.subscriptionStart).format('DD MMM, YYYY')}</td>
                        <td>{ subscription.subscriptionPlanName == 'FREE' ? 'never' :moment(subscription.expiring).format('DD MMM, YYYY')}</td>
                        <td>{subscription.subscriptionPlanName}/{subscription.subscriptionPlan}</td>
                        <td>${subscription.amount}</td>
                        <td>{subscription.paymentStatus}</td>
                        <td>
                            <a target="__blank" href={subscription.paymentReceipt}> Download </a>
                        </td>
                    </tr>)
                })
            }
          />
    </React.Fragment>
  );
};
