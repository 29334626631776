import React from "react";
import "./table.css";
import { BiCaretLeft, BiCaretRight, BiFastForward, BiRewind } from 'react-icons/bi'

const Pagination = ({ paginationPerPage = 10, total = 0, currentPage = 1, changeCurrentPageHandler = () => { }, changePaginationPerPageHandler = () => { } }) => {
    return (
        <div className={`d-flex justify-content-between ps-3 pb-3 pt-3_5 paginationBorder`}>
            <div className="d-flex align-items-center">
                <span className="recordsPerText">{(currentPage - 1) * paginationPerPage + 1}- {paginationPerPage * currentPage > total ? total : paginationPerPage * currentPage} of {total}</span>
                <select value={paginationPerPage} onChange={changePaginationPerPageHandler} className="pageDropdown" >
                    {/* <option value="2">2</option> */}
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                </select>
                <span className="rowsPerText">rows per page</span>
            </div>
            <div className="d-flex align-items-center">
                <BiRewind
                    size={20}
                    color='#3A4DFD'
                    className="pointer"
                    onClick={() => {
                        if (currentPage == 1) return;
                        changeCurrentPageHandler(1)
                    }}
                />
                <div style={{ width: 5 }}></div>
                <BiCaretLeft
                    size={16}
                    color='#3A4DFD'
                    className="pointer"
                    onClick={() => {
                        if (currentPage == 1) return;
                        changeCurrentPageHandler(currentPage - 1)
                    }}
                />

                <div style={{ width: 5 }}></div>
                <span className="viewPageText">Page {currentPage} of {Math.ceil(total / paginationPerPage)}</span>
                <div style={{ width: 5 }}></div>

                <BiCaretRight
                    size={16}
                    color='#3A4DFD'
                    className="pointer"
                    onClick={() => {
                        if (currentPage == Math.ceil(total / paginationPerPage)) return;
                        changeCurrentPageHandler(currentPage + 1)
                    }}
                />
                <div style={{ width: 5 }}></div>
                <BiFastForward
                    size={20}
                    color='#3A4DFD'
                    className="pointer"
                    onClick={() => {
                        if (currentPage == Math.ceil(total / paginationPerPage)) return;
                        changeCurrentPageHandler(Math.ceil(total / paginationPerPage))
                    }}
                />
            </div>
            <div style={{ width: '18%' }}></div>
        </div>
    );
};

export default Pagination;