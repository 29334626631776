import React from 'react'

const Divider = (props) => {
    const { className } = props;
  return (
    <div className={className} style={dividerStyle.div}></div>
  )
}

const dividerStyle = {
    div: {
        height: '1px',
        background: '#D9D9D9',
        marginTop: '10px'
    }
};

export default Divider