
export const DEFAULT_PRICING ={
    "FREE" : {
        type : 'Free Trial',
        priceMonthly : 0,
        priceYearly : 0,
        features : [
            {
                feature : '3 Distributions / Month',
                isAllow : true,
            },
            {
                feature : 'Email Distributions',
                isAllow : false,
            },
            {
                feature : 'Export Now',
                isAllow : true,
            },
            {
                feature : 'Schedule Exports',
                isAllow : false,
            },
            {
                feature : 'Packages Disappear after 24 Hours',
                isAllow : true,
            }
        ],
        allowAll : false,
        limits : {
            distributions : 3,
            export : ['Export'],
            schedule : 'Once'
        }
    },
    "BASIC" : {
        type : 'Basic',
        priceMonthly : 10,
        priceYearly : 110,
        features : [
            {
                feature : '20 Distributions / Month',
                isAllow : true,
            },
            {
                feature : 'Email Distributions',
                isAllow : true,
            },
            {
                feature : 'Export Now',
                isAllow : true,
            },
            {
                feature : 'Schedule Exports',
                isAllow : true,
            },
            {
                feature : 'Packages Disappear after 24 Hours',
                isAllow : true,
            }
        ],
        allowAll : true,
        limits : {
            distributions : 20,
            export : ['Export','Email'],
            schedule : ''
        }
    },
    "PREMIUM" :{
        type : 'Premium',
        priceMonthly : 25,
        priceYearly : 275,
        features : [
            {
                feature : '20 Distributions / Month',
                isAllow : true,
            },
            {
                feature : 'Email Distributions',
                isAllow : true,
            },
            {
                feature : 'Export Now',
                isAllow : true,
            },
            {
                feature : 'Schedule Exports',
                isAllow : true,
            },
            {
                feature : 'Packages Disappear after 24 Hours',
                isAllow : false,
            }
        ],
        allowAll : true,
        limits : {}

    }
}

