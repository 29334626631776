import { GET_PACKAGES, REMOVE_PACKAGE } from "./package.action"

const initialState = {
    packages: [],
    totalPackages: 0,
}

const PackageReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_PACKAGES:
            return {
                ...state,
                packages: action.value.data,
                totalPackages: action.wantCount ? action.value.totalRecords : state.totalPackages
            }

        case REMOVE_PACKAGE:

           try {
            // console.log('action.value',action.value)

            const clonePackages = [...state.packages];
            const findIndex = clonePackages.findIndex(p => p._id == action.value);
            // console.log('findIndex',findIndex)

            if(findIndex !== -1){
                clonePackages.splice(findIndex, 1);
            }

            return {
                ...state,
                packages: clonePackages,
                totalPackages: state.totalPackages - 1
            }

           } catch (error) {

            console.log('error',error)
            return state
            
           }
           


        default:
            return state

    }
}

export default PackageReducer;