import React from "react";
import { useNavigate } from "react-router-dom";
import { SUCCESS_EXPORT } from "../../assets/images.config";
import Default from "../../pages/default/Default";
import "./UIStyles.css";

const Success = () => {
  const navigate = useNavigate();
  return (
    <Default showDistributions={true}>
      <main className="d-flex flex-column justify-content-center align-items-center main-success">
        <h1 className="export-title">Success!</h1>
        <p className="export-description">
          Your package has been exported. Please click return to go back to your
          list of Distributions.
        </p>
        <img src={SUCCESS_EXPORT} className="success-image" alt="" />
      </main>
      <button
        type="button"
        className={`nextButton btn-default border-0 shadow`}
        onClick={() => {
          navigate('/distributions')
        }}
      >
        RETURN
      </button>
    </Default>
  );
};

export default Success;
