import { GET_REPORTS } from "./report.action"

const initialState = {
    reports: [],
    totalReports: 0,
}

const ReportReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_REPORTS:
            return { ...state, 
                reports: action.value.reports, 
                totalReports : action.value.total 
            }
        default:
            return state

    }
}

export default ReportReducer;