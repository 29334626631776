import React from "react";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import { FAQ_HEADER, PACKAGES_BG } from "../../assets/images.config";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./faq.css";

import 'react-accessible-accordion/dist/fancy-example.css';


// const faqData = [
//   {
//     question: "What is Rapid Reports?",
//     answer:
//       "Rapid Reports is a reporting software that allows businesses to generate custom reports and analyze their data efficiently.",
//   },
//   {
//     question: "Is Rapid Reports suitable for my business?",
//     answer:
//       "Rapid Reports is designed to be flexible and can be used by businesses of various sizes and industries to create personalized reports.",
//   },
//   {
//     question: "Can I access Rapid Reports from anywhere?",
//     answer:
//       "Yes, Rapid Reports is a web-based application that can be accessed from any device with an internet connection.",
//   },
//   {
//     question: "How can I ensure the security of my data in Rapid Reports?",
//     answer:
//       "Rapid Reports employs robust security measures, including encryption and user authentication, to safeguard your data.",
//   },
//   {
//     question: "Can Rapid Reports integrate with other business tools?",
//     answer:
//       "Yes, Rapid Reports offers integration capabilities with various third-party applications, allowing seamless data transfer and analysis.",
//   },
//   {
//     question: "Does Rapid Reports provide real-time reporting?",
//     answer:
//       "Yes, Rapid Reports provides real-time reporting, ensuring that you have up-to-date information for accurate analysis and decision-making.",
//   },
//   {
//     question:
//       "Can I customize the appearance of reports in Rapid Reports?",
//     answer:
//       "Absolutely! Rapid Reports offers customization options, allowing you to personalize the appearance and layout of your reports.",
//   },
//   {
//     question: "How user-friendly is Rapid Reports?",
//     answer:
//       "Rapid Reports is designed with a user-friendly interface and intuitive features, making it easy for users to create and navigate reports.",
//   },
//   {
//     question:
//       "Can I collaborate with team members on reports in Rapid Reports?",
//     answer:
//       "Yes, Rapid Reports supports collaboration by allowing multiple users to work on reports simultaneously and share them with team members.",
//   },
//   {
//     question:
//       "Does Rapid Reports offer automated scheduling for report generation?",
//     answer:
//       "Yes, Rapid Reports provides automated scheduling options, allowing you to set up regular report generation and delivery as per your preferences.",
//   },
// ];

const faqData = [
  {
    question: "What is Rapid Reports?",
    answer:
      "Rapid Reports is an app that allows QuickBooks users to easily access, download, and email their financial reports on scheduled intervals.",
  },
  {
    question: "How does Rapid Reports work?",
    answer:
      "Rapid Reports connects to your QuickBooks account, retrieves your financial reports, and presents them in a user-friendly interface within the app. You can create report packages, set distribution frequencies, and choose to export or email these packages.",
  },
  {
    question: "Can I schedule the distribution of reports?",
    answer:
      "Yes, depending on your subscription tier. The Basic and Premium tiers allow you to schedule email distributions as per your desired frequency.",
  },
  {
    question: "What are the distribution frequency options?",
    answer:
      "Rapid Reports offers various distribution frequency options, including daily, weekly, biweekly, monthly, quarterly, and annually. You can select the frequency that suits your needs.",
  },
  {
    question: "What happens when I choose Export for distribution?",
    answer:
      "If you select Export, the reports will be added to the package list according to the chosen distribution frequency. You can then download the reports directly from the package list within the app.",
  },
  {
    question: "How long are the packages available for download?",
    answer:
      "In the Free and Basic subscription tiers, the packages disappear after 24 hours. However, in the Premium tier, the packages stay available for download indefinitely.",
  },
  {
    question:
      "Can I send the reports via email?",
    answer:
      "Yes, you can choose the Email distribution option. Specify the desired email addresses, and Rapid Reports will send the report package to those addresses at the selected distribution frequency. NOTE : This option is not available on the free tier.",
  },
  {
    question: "What are the subscription pricing tiers?",
    answer:
      "Rapid Reports offers three subscription tiers: Free, Basic, and Premium. The Free tier is, as it suggests, “Free” but with limited features. The Basic tier costs $10 per month, and the Premium tier costs $25 per month, both with additional features and increased distribution limits. Access your subscription on the “Settings” page.",
  },
  {
    question:
      "Can I upgrade or downgrade my subscription?",
    answer:
      "Yes, you can upgrade or downgrade your subscription tier at any time. Simply visit the Rapid Reports settings page or contact the support team to make the necessary changes.",
  },
  {
    question:
      "Is Rapid Reports compatible with all versions of QuickBooks?",
    answer:
      "Rapid Reports is designed to work with QuickBooks online accounts. However, it's always recommended to check the compatibility details or contact Rapid Reports support for specific version compatibility.",
  },
];

// const AccordionItem = ({data, index}) => {
//   return (
//     <div className="accordion-item">
//       <h2 className="accordion-header" id={`rr-faq-${index}`}>
//         <button
//           className="accordion-button collapsed"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target={`#rr-faq-${index}`}
//           aria-expanded="false"
//           aria-controls={`rr-faq-${index}`}
//         >
//           {data.question}
//         </button>
//       </h2>
//       <div
//         id={`rr-faq-${index}`}
//         className="accordion-collapse collapse"
//         aria-labelledby={`rr-faq-${index}`}
//         data-bs-parent="#accordionForFAQ"
//       >
//         <div className="accordion-body">{data.answer}</div>
//       </div>
//     </div>
//   );
// };

const FAQ = () => {

  return (
    <Default checkAuth={true}  pathHeader="FAQ" pathLink="/faq">
      <main>
        <PageTitle
          image={FAQ_HEADER}
          imageClass="background  opacity-50"
          description="Read our FAQs below to learn how to use Rapid Reports"
        />

      <div className="tableContainer shadow">
        <div className="row">
          <div className="col-12">
            <Accordion className="qb-act">
              {faqData.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.question}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.answer}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
        </div>
      </main>
    </Default>
  );
};

export default FAQ;
