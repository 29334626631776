import React from "react";

import "./subscription.css";
import { GoCheck,GoX } from "react-icons/go";
import { LOGO } from "../../assets/images.config";

import StripeCheckout from "react-stripe-checkout";
import PostRequest from "../../services/PostRequest";

import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import moment from "moment";



const SubscriptionCard = (props) => {

    const navigate = useNavigate();

  const {
    isActive = false,
    planName = "FREE",
    price = 0,
    type = "7 Days",
    actionName = "Start Free Trial",
    activePlan,
    onToken,
    features = [],
    subscriptionAmount,
    isPaymentAlow,
    handlePaymentModelShow,
    currentPlan = false
  } = props;


  // console.log('currentPlan',currentPlan.subscriptionPlanName)
  // console.log('planName',planName)



  const handleFreeTrial = async (id) => {


    try {

        const subscription = await PostRequest('/subscription/free_trial',{},id);
        // console.log('subscription',subscription)


        Swal.fire({
            icon: 'success',
            title: `Your Free plan is activated now.`,
            text: `Your Free plan is successfully activated, Please upgrade your plan to use More features to avoid limitation`,
        })
        .then((result) => {
            if (result.isConfirmed) {
                navigate('/settings')
            }
        })

    } catch (error) {

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
        })

        console.log('Error',error)
        
    }

  }

  return (
    <div className={`${isActive ? "active" : ""} generic_content clearfix`}>

      <div className="generic_head_price clearfix">
        <div className="generic_head_content clearfix">
          <div className="head_bg" />
          <div className="head">
            <span>{planName}</span>
          </div>
        </div>
        <div className="generic_price_tag clearfix">
          <span className="price">
            <span className="sign">$</span>
            <span className="currency">{price}</span>
            <span className="cent">.00</span>
            <span className="month">/{type}</span>
          </span>
        </div>
      </div>
      <div className="generic_feature_list">
        <ul>
          {features.map((f, i) => (
            <li key={i}>
              {f.isAllow && <span className="pe-2"> <GoCheck  size={25} color={"#3A4DFD"} /></span>}
              {!f.isAllow && <span className="pe-2"> <GoX size={25} color={"#3A4DFD"} /></span>}
              {f.feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="generic_price_btn clearfix">
        {isPaymentAlow ? (
         <div>
          <button disabled={(currentPlan && currentPlan?.subscriptionPlanName == planName)} onClick={(currentPlan && currentPlan?.subscriptionPlanName == planName ? () => {} : handlePaymentModelShow)} className="btn btn-round btn-primary">
          {(currentPlan && currentPlan?.subscriptionPlanName == planName ? 'Current Plan' : actionName) }
        </button>
         </div>
          // <StripeCheckout
          //   name="Rapid Reports"
          //   description={`you are paying for ${activePlan} Subscription.`}
          //   image='https://rapidreports.app/images/logo.png'
          //   ComponentClass="div"
          //   panelLabel="Pay"
          //   amount={price * 100} // cents
          //   currency="USD"
          //   stripeKey="pk_test_51N94FLCbheMPrsNjGVs9sQm4rKWbutmZiI5dyJHlwcvYItQWrInVmBP5bs3nNel0ewrDDB9zThhfTrzrTX0yayDR00C4f4n0zR"
          //   billingAddress={false}
          //   zipCode={true}
          //   token={(token)=> onToken(token,price,planName)} // submit callback
          //   reconfigureOnUpdate={false}
          // >
          //   <button className="btn btn-round btn-primary">
          //     {" "}
          //     {actionName}{" "}
          //   </button>
          // </StripeCheckout>
        ) : (
          <button disabled={(currentPlan && currentPlan?.subscriptionPlanName == planName)} id="sub-2"  onClick={()=> handleFreeTrial('sub-2')} className="btn btn-round btn-primary">{(currentPlan && currentPlan?.subscriptionPlanName == planName ? 'Current Plan' : actionName) } </button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
