import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/UI/buttons/Button";
import { useState } from "react";
import { FINALIZE } from "../../../assets/images.config";
import "../distribution.css"

const FinalizeComponent = ({ handleSubmit }) => {
    const [checked, setChecked] = useState(false);
    const [exportType, setExportType] = useState('PDF')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const navigate = useNavigate();

    const handleChange = (e) => {
        if (e.target.value === "yes") {
            setChecked(true);
        } else {
            setChecked(false);
        }
    };

    const handleExportTypeChange = (e) => {
        setExportType(e.target.value)
    };

    return (
        <>
            <main
                className={`d-flex flex-column align-items-center justify-content-center finalizeContainer`}
            >
                <h3 className="title">Export Reports as</h3>
                <div className={`d-flex radioButtons`}>
                   
                    <div className={`d-flex align-items-center excelRadio`}>
                        <input type="radio" name="export" value="PDF" onChange={handleExportTypeChange} checked={exportType == "PDF"} />
                        <label className="radioLabel">PDF</label>
                    </div>

                    <div className="d-flex align-items-center">
                    <input type="radio" name="export" value="Excel" onChange={handleExportTypeChange} checked={exportType == "Excel"} />
                        <label className="radioLabel">Excel</label>
                    </div>
                    
                </div>
                <h3 className="title">Would you like to password protect this package?</h3>
                <div className={`d-flex radioButtons`}>
                    <div className={`d-flex align-items-center excelRadio`}>
                        <input
                            type="radio"
                            name="protect"
                            value={"yes"}
                            checked={checked}
                            onChange={(e) => handleChange(e)}
                        />
                        <label className="radioLabel">Yes</label>
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            type="radio"
                            name="protect"
                            value={"no"}
                            onChange={(e) => handleChange(e)}
                            defaultChecked
                        />
                        <label className="radioLabel">No</label>
                    </div>
                </div>

                {checked && (
                    <Fragment>
                        <h3 className={`title pb-4_1`}>Please enter a password.</h3>
                        <form onSubmit={(e) => {
                            let formData = new FormData(e.target);
                            e.preventDefault();

                            let data = {};

                            formData.forEach((val, key) => {
                                data[key] = val;
                            });
                            // console.log("data", data)
                            if(data.password != data.confirmPassword) {
                                alert("Password and confirm password not match")
                                return
                            }
                            var old = JSON.parse(localStorage.getItem('distributionData'))
                            localStorage.setItem('distributionData', JSON.stringify({
                                ...old,
                                exportType: exportType,
                                isPasswordProtected: 'true',
                                password: data.password
                            }))
                            handleSubmit()
                        }} >
                            <div className="pe-4_2">
                                <div
                                    className={`d-flex align-items-center justify-content-end pb-3_5`}
                                >
                                    <label className="inputLabel">
                                        password <span className="required ms-0">*</span>{" "}
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="finalizeInput"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div className="d-flex align-items-center">
                                    <label className="inputLabel">
                                        confirm password <span className="required ms-0">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="finalizeInput"
                                        autoComplete="off"
                                        required
                                        defaultChecked
                                    />
                                </div>
                            </div>
                            {password && confirmPassword && <Button
                                type="submit"
                                className={`nextButton btn-default shadow`}
                            >
                                FINALIZE
                            </Button>}
                        </form>
                    </Fragment>
                )}
            </main>
            {!checked && (
                <Button
                    className={`nextButton btn-default shadow`}
                    onClick={() => {
                        var old = JSON.parse(localStorage.getItem('distributionData'))
                        localStorage.setItem('distributionData', JSON.stringify({
                            ...old,
                            exportType: exportType,
                            isPasswordProtected: 'false',
                        }))
                        handleSubmit()
                    }}
                >
                    REVIEW
                </Button>
            )}
            <img src={FINALIZE} className="finalizeImage" alt="" />
            <button
                className={`btn btn-outline-primary mt-3 py-2 btn-previous cancelButton`}
                onClick={() => navigate("/distributions/schedule")}
            >
                PREVIOUS
            </button>
        </>
    );
};

export default FinalizeComponent;
