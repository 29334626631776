import PostRequest from "../../services/PostRequest";

export const GET_REPORTS = 'GET_REPORTS';

export const getReports = (data) => {

    return async dispatch => {

        try {

            const response = await PostRequest('/reports', data)
            

            dispatch({
                type: GET_REPORTS,
                value: response,
            })

        } catch (error) {
            return error
        }


    };
}