import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { DISTRIBUTION_BG } from '../../assets/images.config';
import Divider from '../../components/UI/Divider';
import PageTitle from '../../components/UI/PageTitle';
import SearchInput from '../../components/UI/SearchInput';
import Default from '../default/Default'
import TableCheckbox from '../../components/UI/table/TableCheckBox';
import TableDropdown from '../../components/UI/table/TableDropDown';
import { DateRange } from "react-date-range";
import Pagination from '../../components/UI/table/Pagination';
import Checkbox from '../../components/UI/CheckBox';
import CustomTable from '../../components/UI/table/CustomTable';
import TableTabs from '../../components/UI/table/TableTabs';
import PostRequest from '../../services/PostRequest';
import moment from 'moment';

const Distributions = () => {

    const [isFirstRender, setFirstRender] = useState(true);

    const [activeElement, setActiveElement] = useState(0);
    const [total, setTotal] = useState(0);
    const [distributions, setDistributions] = useState([]);
    // const [accounts, setAccounts] = useState({});
    const [selectedRows, setSelectedRows] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const [filters, setFilters] = useState({
        distributionDate: {
            startDate: new Date(),
            endDate: null,
            key: "selection",
        },
        name: '',
        method: [],
        frequency: [],
        recipientEmail: '',
        status: [],
        message: ''
    })

    const isFilterEmpty = () => {
        var filterData = { ...filters }
        if (filterData.name == '' &&
            filterData.recipientEmail == '' &&
            filterData.message == '' &&
            filterData.distributionDate.endDate == null &&
            filterData.frequency.length == 0 &&
            filterData.method.length == 0 &&
            filterData.status.length == 0
        ) return true;
        return false;
    }

    const handleActiveElement = (id) => {
        setActiveElement(id);
    };

    const loadData = async (isFirstTime) => {
        const d = {
            wantCount: isFirstTime,
            skip: currentPage == 1 ? 0 : limit * (currentPage - 1),
            limit: limit
        }

        var filterData = { ...filters }
        if (filterData.name == '') delete filterData.name
        if (filterData.recipientEmail == '') delete filterData.recipientEmail
        if (filterData.message == '') delete filterData.message
        if (filterData.distributionDate.endDate == null) delete filterData.distributionDate
        if (filterData.frequency.length == 0) delete filterData.frequency
        if (filterData.method.length == 0) delete filterData.method
        if (filterData.status.length == 0) delete filterData.status

        d['filters'] = filterData
        if (search != '') d['search'] = search

        try {
            setLoading(true)
            const response = await PostRequest('/distributions', d);
            setDistributions(response.data)
            // setAccounts(response.data.accounts)
            if (isFirstTime == 'true' && response.totalRecords) setTotal(response.totalRecords)
        } catch (error) {
            console.log("Error...", error)
        } finally {
            setLoading(false)
        }

    }

    const updateDistributionStatus = async (id,status) => {

       if(window.confirm("Are you sure you want to cancel this distribution")){

        try {
            await PostRequest('/distribution/update',{ distribution : id, status : status});
            loadData('true')
        } catch (error) {
            alert(error);
        }

       }

    }

    useEffect(() => {
        loadData("true")
    }, [])

    useEffect(() => {
        if (!isFirstRender) {
            loadData(null);
        } else {
            setFirstRender(false)
        }
    }, [search, limit, currentPage, filters])

    return (
        <Default
            pathHeader="Distributions"
            pathLink="/distributions"
        >

            <main>
                <PageTitle
                    image={DISTRIBUTION_BG}
                    description="Manage your list of distributions below, or create a new one by
                            clicking “Create New Distribution”"
                />

                <div className="tableContainer shadow">
                    <div className="d-flex justify-content-end ms-auto me-3_5 pt-4 pb-3">
                        <SearchInput placeholder="Search Distribution" value={search} onChange={(value) => setSearch(value)} />
                        <Link
                            to={"/distributions/create-new"}
                            className={`pb-2 px-2_5 ms-3_5 distributionButton theme-button`}
                        >
                            Create New Distribution
                        </Link>
                    </div>

                    {/* <TableTabs
                        active={activeElement}
                        handleTabChange={(i) => handleActiveElement(i)}
                        tabs={['Active Distributions', 'Inactive Distributions']}
                    /> */}

                    <Divider />

                   
                    <CustomTable
                        header={<tr>
                            <th className='text-center'><TableDropdown headerName="Select">
                                <ul className="dropdown-menu custom-dropdown-menu px-2">
                                    <TableCheckbox
                                        listItems={["Select All"]}
                                    />
                                </ul>
                            </TableDropdown></th>
                            
                            <th>
                                <TableDropdown headerName="Distribution Dates" >
                                    <div className="dropdown-menu custom-dropdown-menu calender-menu">
                                        <DateRange
                                            onChange={(item) => {
                                                setFilters({ ...filters, distributionDate: item.selection })
                                            }}
                                            moveRangeOnFirstSelection={false}
                                            retainEndDateOnFirstSelection={false}
                                            ranges={[filters.distributionDate]}
                                        />
                                    </div>
                                </TableDropdown>
                            </th>
                            <th>
                                <TableDropdown headerName="Distribution Name">
                                    <div className="dropdown-menu custom-dropdown-menu px-2">
                                        <SearchInput value={filters.name} onChange={(value) => { setFilters({ ...filters, name: value }); }} placeholder='Search' />
                                    </div>
                                </TableDropdown>
                            </th>
                            <th className='text-center'>
                                <TableDropdown headerName="Distribution Type">
                                    <ul className="dropdown-menu custom-dropdown-menu px-2">
                                        <TableCheckbox
                                            selectedItems={filters.method}
                                            onChangeSelection={(newList) => { setFilters({ ...filters, method: newList }); }}
                                            listItems={["Email", "Export"]}
                                        />
                                    </ul>
                                </TableDropdown>
                            </th>
                            <th className='text-center'>
                                <TableDropdown headerName="Frequency">
                                    <ul className="dropdown-menu custom-dropdown-menu px-2">
                                        <TableCheckbox
                                            selectedItems={filters.frequency}
                                            onChangeSelection={(newList) => setFilters({ ...filters, frequency: newList })}
                                            listItems={[
                                                "Once",
                                                "Daily",
                                                "Weekly",
                                                "Twice Weekly",
                                                "Bi-weekly",
                                                "Monthly",
                                                "Quarterly",
                                                "Annually",
                                            ]}
                                        />
                                    </ul>
                                </TableDropdown>
                            </th>
                            <th>
                                <TableDropdown headerName='Recipient Email'>
                                    <div className="dropdown-menu custom-dropdown-menu px-2">
                                        <SearchInput value={filters.recipientEmail} onChange={(value) => { setFilters({ ...filters, recipientEmail: value }); }} placeholder='Search' />
                                    </div>
                                </TableDropdown>
                            </th>
                            <th className='text-center'>
                                <TableDropdown headerName="Status">
                                    <ul className="dropdown-menu custom-dropdown-menu px-2">
                                        <TableCheckbox
                                            selectedItems={filters.status}
                                            onChangeSelection={(newList) => { setFilters({ ...filters, status: newList }); }}
                                            listItems={[
                                                "In Progress",
                                                "Complete",
                                                "Failed",
                                                "Cancelled",
                                                "Series Cancelled",
                                            ]}
                                        />
                                    </ul>
                                </TableDropdown>
                            </th>
                            <th>
                                <TableDropdown headerName="Message">
                                    <div className="dropdown-menu custom-dropdown-menu px-2_5">
                                        <SearchInput isTextarea={true} value={filters.message} onChange={(value) => { setFilters({ ...filters, message: value }); }} placeholder='Search' />
                                    </div>
                                </TableDropdown>
                            </th>
                            <th className='text-center'>
                                Action
                            </th>
                        </tr>}
                        body={distributions.map((row, i) => {
                            return <tr key={i}>
                                <td className='text-center' style={{ width: 80 }}>
                                    <Checkbox
                                        value={row._id}
                                        checked={selectedRows.includes(row._id.toString())}
                                        left={20}
                                        onChange={(e) => {
                                            var isChecked = e.target.checked;
                                            var exist = selectedRows.findIndex((item) => item == e.target.value)
                                            var clone = [...selectedRows]
                                            if (isChecked && exist == -1) clone.push(e.target.value)
                                            if (!isChecked && exist != -1) clone.splice(exist, 1)
                                            setSelectedRows(clone)
                                        }}


                                    />
                                </td>
                             
                                <td className='w-20'>{moment(row.schedule.scheduleStartDate).format('DD/MM/YY')} - {moment(row.schedule.scheduleEndDate).format('DD/MM/YY')} at {moment(row.schedule.scheduleTime,'HH:mm').format('hh:mm A')} </td>
                                <td className='w-10'>{row.name}</td>
                                <td className='text-center w-10'>{row.method}</td>
                                <td className='text-center w-10'>{row.schedule.scheduleFrequency}</td>
                                <td className='w-10'>{row.emailDraft?.to || '--'}</td>
                                <td className='text-center w-10'>{row.status || '--'}</td>
                                <td>{row.message || "--"}</td>
                                <td className='text-center text-danger pointer'> 
                                    { row.status !== "Cancelled" ? <span onClick={ () => updateDistributionStatus(row._id,'Cancelled') }> Cancel </span> : null }
                                </td>
                            </tr>
                        })}
                    />
                    {distributions.length > 0 && <Pagination
                        total={total}
                        currentPage={currentPage}
                        changeCurrentPageHandler={(page) => setCurrentPage(page)}
                        paginationPerPage={limit}
                        changePaginationPerPageHandler={(e) => {
                            setCurrentPage(1)
                            setLimit(e.target.value)
                        }}
                    />}
                </div>
            </main>

        </Default>
    )
}

export default Distributions