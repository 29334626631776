import React, { useState } from "react";
import PricingTable from "./PricingTable";
import { LOGO, LOGO_NEW, LOGO_NEW_WITH_COLOR } from "../../assets/images.config";


const Subscribe = () => {
  

  return (
    <div  className="container">

        <div className="row">

            <div className="col-12 text-center  align-items-center pt-5 pb-2">
                
                <div className="d-flex align-items-center justify-content-center pt-3 ps-4 pb-3">
                    {/* <img src={LOGO} className="logo" alt="" />
                    <h1 className="logoText">Rapid Reports</h1> */}
                     <img src={LOGO_NEW_WITH_COLOR} height={50} alt="" />
                </div>

                <p>
                    Choose your tier and start getting your reports when you want them.
                </p>
            </div>

        </div>

      <div className="p-2">
        <PricingTable  
          showFreePlan={true} />
      </div>
    </div>
  );
};

export default Subscribe;
