import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { REVIEW } from "../../../assets/images.config";
import { useState } from "react";
import Success from "../../../components/UI/Success";
import ProgressBar from "../../../components/UI/ProgressBar"
import Default from "../../default/Default";
import '../distribution.css'
import PostRequest from "../../../services/PostRequest";
import ErrorPage from "../../../components/UI/ErrorPage";

const ReviewComponent = ({ isEmail = false }) => {

    const navigate = useNavigate();

    const [isLaunching, setIsLaunching] = useState(false);
    const [isLaunched, setIsLaunched] = useState(false);
    const [data, setData] = useState({})
    const [reportLabels, setReportLabels] = useState("")

    const [navigateURL, setNavigateURL] = useState("")
    const [navigateTital, setNavigateTital] = useState("Go Back")

    const [hasError, setHasError] = useState(false);

    const handleClick = async () => {
        try {
            setIsLaunching(true);
            // var id = data.account.Id;
            const newData = { ...data }
            // delete newData.account;
            // newData.account = id
            await PostRequest('/distribution/create', newData);
            // localStorage.removeItem('distributionData')
            setTimeout(() => {
                setIsLaunching(false);
                setIsLaunched(true)
            }, 3000)
        } catch (e) {

            try {

                var msg = JSON.parse(e);

                setNavigateURL('/subscription/renew')
                setNavigateTital('Show Me Plans')
                setHasError(msg.message)

            } catch (error) {
                setHasError(e)
                setNavigateURL('/distributions/finalize-email')
                setNavigateTital('Go Back')
            }

            // alert(error)
            
            // localStorage.removeItem('distributionData')
            // navigate('/distributions')
        }
    };

    useEffect(() => {
        var parsed = JSON.parse(localStorage.getItem('distributionData') || "{}")
        var reports = [];
        var label = ""
        parsed.reports.map((r) => {
            var parsedReport = JSON.parse(r)
            reports.push(parsedReport)
            label = `${label}${parsedReport.label}, `
        });
        setData({ ...parsed, reports: reports })
        setReportLabels(label)
    }, [])


    const navigateTo = () => {
        navigate(navigateURL);
    }

    return (
        <Fragment>
            {isLaunching ?
                
                hasError == false ? <ProgressBar /> : <ErrorPage 
                error="Oops!!!!!!!"
                errorMessage={hasError}
                navigateTo={navigateTo}
                navigateToText={navigateTital}
                
                />

                : isLaunched
                    ? <Success />
                    :
                    <Default
                        paths={[
                            { label: "Create New", link: "/distributions/create-new" },
                            { label: "Reports", link: "/distributions/reports" },
                            { label: "Distribution Method", link: "/distributions/methods" },
                            { label: "Schedule Distribution", link: `/distributions/schedule${isEmail ? '-email' : ''}` },
                            { label: "Draft Email Template", link: "/distributions/draft-email", hidden: !isEmail },
                            { label: "Finalize", link: `/distributions/finalize${isEmail ? '-email' : ''}` },
                            { label: "Review" }
                        ]}
                        pathHeader="Distribution"
                        pathLink="/distributions"
                    >
                        <main className="d-flex flex-column align-items-center justify-content-center">
                            <h2 className="heading py-4">
                                Review your Distribution
                            </h2>
                            <form action="">
                                <div className="d-flex justify-content-end">
                                    <label htmlFor="" className="review-label">
                                        Distribution Name
                                    </label>
                                    <input
                                        type="text"
                                        className="input"
                                        value={data.name || ''}
                                        readOnly
                                    />
                                </div>
                                {/* <div className="d-flex justify-content-end py-4_2">
                                    <label htmlFor="" className="review-label">
                                        Account Name
                                    </label>
                                    <input
                                        type="text"
                                        className="input"
                                        value={data.account?.FullyQualifiedName || ''}
                                        readOnly
                                    />
                                </div> */}
                                <div className="d-flex justify-content-end py-4_2">
                                    <label htmlFor="" className="review-label">
                                        Reports Included
                                    </label>
                                    <input
                                        type="text"
                                        className="input"
                                        value={reportLabels}
                                        readOnly
                                    />
                                </div>
                                <div className="d-flex justify-content-end ">
                                    <label htmlFor="" className="review-label">
                                        Distribution Method
                                    </label>
                                    <input type="text" className="input" value={data.method} readOnly />
                                </div>
                                <div className="d-flex justify-content-end py-4_2">
                                    <label htmlFor="" className="review-label">
                                        File Type
                                    </label>
                                    <input type="text" className="input" value={data.exportType} readOnly />
                                </div>
                                <div className="d-flex justify-content-end ">
                                    <label htmlFor="" className="review-label">
                                        Password Protected
                                    </label>
                                    <div className="d-flex w-400px">
                                        <div
                                            className={`d-flex align-items-center excelRadio`}
                                        >
                                            <input type="radio" name="protect" checked={data.isPasswordProtected == 'true'} value={"yes"} disabled />
                                            <label className="radioLabel">Yes</label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="protect"
                                                value={"no"}
                                                disabled
                                                checked={data.isPasswordProtected == "false"}
                                            />
                                            <label className="radioLabel">No</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </main>
                        <button
                            type="button"
                            className={`nextButton btn-default border-0 shadow`}
                            onClick={handleClick}
                        >
                            LAUNCH
                        </button>
                        <img src={REVIEW} className="reviewImage" alt="" />
                        <button
                            type="button"
                            className={`btn btn-outline-primary mt-3 py-2 btn-previous cancelButton`}
                            onClick={() => navigate(`/distributions/finalize${isEmail ? '-email' : ''}`)}
                        >
                            PREVIOUS
                        </button>
                    </Default>
            }
        </Fragment>
    );
};

export default ReviewComponent;
