import React, { useEffect, useState } from "react";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import { LOGO, PACKAGES_BG, SUBSCRIPTION_BG } from "../../assets/images.config";
import SubscriptionCard from "./SubscriptionCard";
import StripeCheckout from 'react-stripe-checkout';
import moment from "moment";
import PostRequest from "../../services/PostRequest";

import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { DEFAULT_PRICING } from "../../config/pricing";
import PricingTable from "./PricingTable";



const featuresList = [
    'Manage Distributions',
    'Create Distributions',
    'Schedule Distributions',
    'Export Distributions',
    'Download PDF Reports',
    'Download Excel Reports',
    'Manage Packages',
    'Unlimited Users',
    'Unlimited Schedules',
]


const Subscription = () => {

    const navigate = useNavigate();


  return (
    <Default 
        className="enable-page-scrolling"
        pathHeader="Subscription" 
        pathLink="/subscription">
      <main>
        <PageTitle
          image={PACKAGES_BG}
          imageClass="background"
          description="Choose your tier and start getting your reports when you want them."
        />

        <PricingTable showFreePlan={true} />
       
      </main>

      <button
        type="button"
        className={`btn btn-outline-primary mt-3 py-2 btn-previous cancelButton`}
        onClick={() => navigate(`/settings`)}
      >
          PREVIOUS
      </button>
    </Default>
  );
};

export default Subscription;
