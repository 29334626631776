import React from "react";
import "../UIStyles.css";

const Button = (props) => {
  return (
    <button
      type={props.type ? props.type : "button"}
      onClick={props.onClick}
      className={`theme-button ${props.className}`}
      id={props.id ? props.id : ""}
    >
      {props.children}
    </button>
  );
};

export default Button;