import React from "react";
import "./default.css";
import {
    ACCOUNTS,
    ADMINISTRATION,
    ANALYTICS,
    APERTURE,
    CALENDER,
    CHEVRON,
    CREDIT_CARD,
    DASHBOARD,
    DISTRIBUTIONS,
    HAMBURGER,
    LOGO,
    LOGO_NEW,
    PACKAGES,
    SETTINGS,
    SUPPORT,
} from "../../assets/images.config";
import { NavLink } from "react-router-dom";

import { GoPrimitiveDot } from "react-icons/go";

const navItems = [
    // {
    //     icon: DASHBOARD,
    //     name: "Dashboard",
    //     link: "/",
    // },
    // {
    //     icon: ACCOUNTS,
    //     name: "Accounts",
    //     link: "/accounts",
    // },
    {
        icon: DISTRIBUTIONS,
        name: "Distributions",
        link: "/distributions",
    },
    {
        icon: PACKAGES,
        name: "Packages",
        link: "/packages",
    },
    
    // {
    //     icon: CREDIT_CARD,
    //     name: "Subscriptions",
    //     link: "/subscriptions",
    // },
    
    // {
    //     icon: CALENDER,
    //     name: "Calendar",
    //     link: "/calender",
    // },
    // {
    //     icon: ANALYTICS,
    //     name: "Analytics",
    //     link: "/analytics",
    // },
    // {
    //     icon: ADMINISTRATION,
    //     name: "Administration",
    //     link: "/administration",
    // },
    {
        icon: SETTINGS,
        name: "Settings",
        link: "/settings",
    },
    {
        icon: APERTURE,
        name: "FAQ",
        link: "/faq",
    },
    {
        icon: SUPPORT,
        name: "Contact Us",
        link: "/contact-us",
    },

  
    // {
    //     icon: APERTURE,
    //     name: "Help",
    //     link: "/aperture",
    // },
];

const Sidebar = () => {
    return (
        <nav className="sidebar">
            <div className="pt-2 ps-2">
                <img src={HAMBURGER} className="hamburger" alt="" />
            </div>
            <div className="d-flex align-items-center pt-3 ps-4 pb-3">
                <img src={LOGO_NEW} className="logo" alt="" />
                {/* <h1 className="logoText">Rapid Reports</h1> */}
            </div>
            <div className="list-group list-group-flush">
                {navItems.map((item, i) => {
                    return (
                        <NavLink
                            to={item.link}
                            className={({ isActive }) => `${isActive ? 'active' : ''} navLink text-decoration-none`}
                            key={i}
                        >
                            <div className="d-flex align-items-center justify-content-between py-3 px-4">
                                <img src={item.icon} alt="" className="navIcon" />
                                <p
                                    className={`text-white mb-0 me-auto ps-2 navText`}
                                >
                                    {item.name}
                                </p>
                                {/* <img src={CHEVRON} className="navIcon" alt="" /> */}
                            </div>
                        </NavLink>
                    );
                })}
            </div>
                
{/* 
            <div className="sidebar-footer d-flex align-items-center justify-content-around">
                <NavLink to={'/rapid-reports/faq'} > <GoPrimitiveDot/> FAQ</NavLink>
                <NavLink to={'/rapid-reports/contact-us'} > <GoPrimitiveDot/> Contact Us</NavLink>
            </div> */}

        </nav>
    );
};

export default Sidebar;
