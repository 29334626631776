import React, { forwardRef } from "react";

const Checkbox = forwardRef(({ onChange, checked, left = 0, top = 0, ...rest }, ref) => {
  const renderCheckbox = () => {
    return (
      <label className="checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          ref={ref}
          {...rest}
        />
        <span className="checkmark" style={{ left: left, top: top }}></span>
      </label>
    );
  };

  if (rest.name === "select-all-rows") {
    return (
      <div className="dropdown ps-2_5">
        <a
          href="!#"
          data-bs-toggle="dropdown"
          className="text-decoration-none dropdown-toggle dropdown-link"
        >
          Select
        </a>
        <ul className="dropdown-menu custom-dropdown-menu">
          <li className="ps-2">
            <div className="d-flex align-items-center">
              {renderCheckbox()} <label htmlFor="" className="ms-4">Select All</label>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  return renderCheckbox();
});

export default Checkbox;
