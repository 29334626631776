import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/UI/buttons/Button';
import { LOGO_NEW, LOGO_NEW_WITH_COLOR } from '../../assets/images.config';

import './login.css';
import QBRequest from '../../services/QBRequest';
import PostRequest from '../../services/PostRequest';
import { useNavigate } from 'react-router-dom';

import Swal from "sweetalert2";

const Disconnect = () => {

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);

    const handleLoginWinIntuit = async () => {
      
        if(isLoading) return false;

        try {
            setIsLoading(true);
            const authorize = await QBRequest('/authorize');
            window.location.href = authorize.oAuthRedirectUri;

        } catch (error) {
            setIsLoading(false) ;
        } 

    }


    useEffect(() => {
        handleLoginWinIntuit();
    },[])

  return (
    <Container>
    <Row>
      <Col>

            <div className='h-100vh d-flex justify-content align-items-center'>

                <div className='m-auto p-3'>

                    <div className='text-center mb-5'>
                        <a href='https://rapidreports.app/'>
                            <img src={LOGO_NEW_WITH_COLOR} height={50} alt="" />
                        </a>
                    </div>


                    <div className='text-center mb-5'>
                        <h3> Authenticating... </h3>
                    </div>

                </div>

            </div>

      </Col>
    </Row>
  </Container>
  )
}

export default Disconnect