import React, { useEffect, useState } from "react";


import Swal from "sweetalert2";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import Sizebox from "../../components/UI/Sizebox";
import Button from "../../components/UI/buttons/Button";
import { FINALIZE } from "../../assets/images.config";

import {FiHelpCircle} from 'react-icons/fi'
import PostRequest from "../../services/PostRequest";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {

  const navigate = useNavigate();


  const [companyName,setCompanyName] = useState();
  const [companyEmail,setCompanyEmail] = useState();


  const [profile,setProfile] = useState(false);


  const getProfile = () => {


    PostRequest('/profile',{})
    .then(profile => {
      setProfile(profile)
    })
    .catch(err => {
        console.error(err);
    })

  }


  useEffect(() => {
    setCompanyName(localStorage.getItem('CompanyName') || '')
    setCompanyEmail(localStorage.getItem('CompanyEmail') || '')

    getProfile();
  },[])

  const handleProfileUpdate = async (e) => {
    

    try {

      let formData = new FormData(e.target);
      e.preventDefault();

      let data = {};

      formData.forEach((val, key) => {
        data[key] = val;
      });


      if(data.password !== '' && data.password !== data.confirmPassword){
       
        Swal.fire({
          icon: "error",
          title: 'Invalid Password',
          text: 'Password and Confirm Password not match!',
        });

        return false;

      } 


      const u = await PostRequest('/profile/update',data,'update-profile-button')

      localStorage.setItem('CompanyName',u.companyInfo.CompanyName)
      localStorage.setItem('CompanyEmail',u.email)


      setProfile(u);


      

      Swal.fire({
        icon: "success",
        title: 'Profile Updated',
        text: '',
      });
      
      
    } catch (error) {

      Swal.fire({
        icon: "error",
        title: 'OOpps!!',
        text: error,
      });

      
    }

   

  };

  const handleLogout = () => {
    PostRequest('/logout');
    localStorage.clear();
    navigate("/login");
  }

  return (
    <Default pathHeader="Manage Profile" pathLink="/user/profile/edit">
      <main>
        <PageTitle description="Manage your profile" />

        <div style={{ overflowX : 'hidden', height: 'calc(100vh - 100px)'} }>
        <div className="row" >
          <div className="col-md-8 offset-md-2">
            <Sizebox gap={10} />

            <form onSubmit={(e) => handleProfileUpdate(e)}>

              <h5> Personal Details </h5>
              <Sizebox gap={30} />

              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Full Name <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="finalizeInput"
                    autoComplete="off"
                    defaultValue={profile ? profile.name : ''}
                    required
                  />
                </div>
              </div>

              {/* EMAIL */}

              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Email <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="email"
                    name="email"
                    disabled
                    defaultValue={companyEmail}
                    className="finalizeInput"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              {/* PHONE */}
              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">Phone Number &nbsp;</label>
                  <input
                    type="phone"
                    name="phoneNumber"
                    defaultValue={profile ? profile.phoneNumber : ''}
                    className="finalizeInput"
                    autoComplete="off"
                  />
                </div>
              </div>

                <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    Company Name <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="finalizeInput"
                    autoComplete="off"
                    disabled
                    defaultValue={companyName == undefined ? '' : companyName}
                  />
                </div>
              </div>

              <Sizebox gap={30} />
              <h5> { profile.hasPasswordSet ? 'Password Settings' : 'Set New Password' }  </h5>
              <Sizebox gap={30} />

              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel me-2">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="finalizeInput"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel me-2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="finalizeInput"
                    autoComplete="off"
                  />
                </div>
              </div>

              <Sizebox gap={30} />
              <h5> Account Settings <FiHelpCircle className="pointer" title="From Email Address will use for send descriptions using provided Email Address" />  </h5>
              <Sizebox gap={30} />

              <div className="pe-4_2">
                <div
                  className={`d-flex align-items-center justify-content-end pb-3_5`}
                >
                  <label className="inputLabel">
                    From Email Address <span className="required ms-0">*</span>{" "}
                  </label>
                  <input
                    type="email"
                    name="mailFromEmail"
                    className="finalizeInput"
                    autoComplete="off"
                    defaultValue={profile ? profile?.settings?.mailFromEmail : ''}
                    required
                  />
                </div>
              </div>

              <button
                className={`cancelButton btn btn-outline-primary btn-previous`}
                onClick={handleLogout}
              >
                Logout
              </button>
            

                <Button id="update-profile-button" type="submit" className={`nextButton btn-default shadow`}>
                    Update Profile
              </Button>
            </form>
          </div>
        </div>

        </div>

     
        <img src={FINALIZE} className="finalizeImage" alt="" />
      </main>
    </Default>
  );
};

export default EditProfile;
