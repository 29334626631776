import React, { useEffect } from "react";
import Header from "../../components/default/Header";
// import Header from "../../components/default/Header";
import Sidebar from "../../components/default/Sidebar";

import ReactGA from 'react-ga';
import PostRequest from "../../services/PostRequest";
ReactGA.initialize('G-VV07TN85F0');


const Default = (props) => {

  const { className } = props;


  useEffect(() =>{
    ReactGA.pageview(window.location.pathname + window.location.search);

      if(props.checkAuth){
        PostRequest('/checkAuth')
        .then(()=>{

        })
        .catch(e => {})
      }

  },[])

  return (
    <div className={`main ${className}`}>
      <Sidebar />
      <div className="flex-1 p-3 left-sidebar">
        <Header
          paths={props.paths || []}
          pathHeader={props.pathHeader}
          pathLink={props.pathLink}
        />
        <div className="content-container">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Default;