import React, { useEffect, useRef } from "react";
import { PACKAGES_BG } from "../../assets/images.config";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import Divider from "../../components/UI/Divider";
import SearchInput from "../../components/UI/SearchInput";
import Dropdown from "../../components/UI/DropDown";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "./packages.css"
import "../distributions/distribution.css"
import CustomTable from "../../components/UI/table/CustomTable";
import TableCheckbox from "../../components/UI/table/TableCheckBox";
import TableDropdown from "../../components/UI/table/TableDropDown";
import Checkbox from "../../components/UI/CheckBox";
import AccountNameSelector from "../../components/UI/table/AccountNameSelector";
import Pagination from "../../components/UI/table/Pagination";
import TableTabs from "../../components/UI/table/TableTabs";
import { useDispatch, useSelector } from "react-redux";
import { getPackages, removePackage } from "../../store/package/package.action";
import moment from "moment";
import { API_URLS } from "../../config/api.urls";

import {AiOutlineDelete} from 'react-icons/ai'
import {FiLoader} from 'react-icons/fi'

import PostRequest from "../../services/PostRequest";
import DownloadRequest from "../../services/DownloadRequest";


const Packages = () => {

    const dispatch = useDispatch()
    const _packages = useSelector((state) => state.packageReducer.packages);
    const _totalPackages = useSelector((state) => state.packageReducer.totalPackages);

    const [activeElement, setActiveElement] = useState(0);

    const [packages, setPackages] = useState(_packages);
    const [totalPackages, setTotalPackages] = useState(_totalPackages);

    const [date, setDate] = useState(null);
    const [isFirstRender, setFirstRender] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);

    const [isLoading, setLoading] = useState(false)

    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const [isDeleting, setIsDeleting] = useState(false);


    const [filters, setFilters] = useState({
        distributionDate: {
            startDate: new Date(),
            endDate: null,
            key: "selection",
        },
        name: '',
        method: [],
        frequency: [],
        recipientEmail: '',
        status: [],
        message: ''
    })

    const loadData = async (isFirstTime = "false") => {
        if (isLoading) return;
        const d = {
            wantCount: isFirstTime,
            skip: currentPage == 1 ? 0 : limit * (currentPage - 1),
            limit: limit
        }

        var filterData = { ...filters }
        if (filterData.name == '') delete filterData.name
        if (filterData.recipientEmail == '') delete filterData.recipientEmail
        if (filterData.message == '') delete filterData.message
        if (filterData.distributionDate.endDate == null) delete filterData.distributionDate
        if (filterData.frequency.length == 0) delete filterData.frequency
        if (filterData.method.length == 0) delete filterData.method
        if (filterData.status.length == 0) delete filterData.status

        d['filters'] = filterData
        if (search != '') d['search'] = search

        try {
            setLoading(true)
            await dispatch(getPackages(d, isFirstTime))
        } catch (error) {

        } finally {
            setLoading(false)
        }

    }

    const handleActiveElement = (id) => {
        setActiveElement(id);
    };

    const handleDelete = async (id) => {

        if(isDeleting) return false;

        if(window.confirm('Are you sure you want to delete this package?')){
            // Delete

            try {

                setIsDeleting(id);
                await PostRequest('/package/delete',{ package : id });
                // console.log('ID IS',id)
                dispatch(removePackage(id))
                
            } catch (error) {
                alert(error)
                
            } finally{
                setIsDeleting(false);
            }

        } 

    }

    const handleDownloadFile = async (report) => {
        const parts = report.reportPDFUrl.split('/')
        const path = parts[parts.length - 1];
        const type = parts[parts.length - 2];

        try {
            await DownloadRequest(`/reports/export/${type}/${path}`,path)
        } catch (error) {
            alert(error)
        }


    }

    useEffect(() => {
        loadData("true");
    }, [])


    useEffect(() =>{
        setPackages(_packages)
    },[_packages])

    useEffect(() =>{
        setTotalPackages(_totalPackages)
    },[_totalPackages])


    useEffect(() => {
        if (!isFirstRender) {
            loadData(null);
        } else {
            setFirstRender(false)
        }
    }, [search, limit, currentPage, filters])

    return (
        <Default
            pathHeader="Packages"
            pathLink="/packages"
        >
            <main>
                <PageTitle image={PACKAGES_BG} imageClass="background" description="Manage your packages using the list below." />

                <div className="tableContainer shadow">
                    <div className="d-flex justify-content-end ms-auto me-3_5 pt-4 pb-3">
                        <SearchInput placeholder="Search Package" value={search} onChange={(value) => setSearch(value)} />
                        {/* <Dropdown className="px-3" buttonText="Filters">
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                        </Dropdown>
                        <Dropdown buttonText="Actions">
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                        </Dropdown> */}
                    </div>

                    {/* <TableTabs
                        active={activeElement}
                        handleTabChange={(i) => handleActiveElement(i)}
                        tabs={['Scheduled Packages', 'Delivered Packages']}
                    /> */}

                    <Divider />

                    {activeElement === 0 &&
                        <>
                            <CustomTable
                                header={<tr>
                                    <th className='text-center'>
                                        <TableDropdown headerName="Select">
                                            <ul className="dropdown-menu custom-dropdown-menu px-2">
                                                <TableCheckbox
                                                    listItems={["Select All"]}
                                                    selectedItems={["Select All"]}
                                                />
                                            </ul>
                                        </TableDropdown>
                                    </th>

                                    <th className='text-center'>
                                        <TableDropdown headerName="Delivery Date">
                                            <div className="dropdown-menu custom-dropdown-menu calender-menu">
                                            
                                                <DateRange
                                                    onChange={(item) => {
                                                        setFilters({ ...filters, distributionDate: item.selection })
                                                    }}
                                                    moveRangeOnFirstSelection={false}
                                                    retainEndDateOnFirstSelection={false}
                                                    ranges={[filters.distributionDate]}
                                                />
                                            </div>
                                        </TableDropdown>
                                    </th>
                                    <th>
                                        <TableDropdown headerName="Distribution Name">
                                            <div className="dropdown-menu custom-dropdown-menu px-2">
                                                <SearchInput value={filters.name} onChange={(value) => { setFilters({ ...filters, name: value }); }} placeholder='Search' />
                                            </div>
                                        </TableDropdown>
                                    </th>
                                    <th className='text-center'>
                                        <TableDropdown headerName="Distribution Type">
                                            <ul className="dropdown-menu custom-dropdown-menu px-2">
                                                <TableCheckbox
                                                    selectedItems={filters.method}
                                                    listItems={["Email", "Export"]}
                                                    onChangeSelection={(newList) => { setFilters({ ...filters, method: newList }); }}
                                                />
                                            </ul>
                                        </TableDropdown>
                                    </th>
                                    <th className='text-center'>
                                        <TableDropdown headerName="Frequency">
                                            <ul className="dropdown-menu custom-dropdown-menu px-2">
                                                <TableCheckbox
                                                    selectedItems={filters.frequency}
                                                    onChangeSelection={(newList) => setFilters({ ...filters, frequency: newList })}
                                                    listItems={[
                                                        "Once",
                                                        "Daily",
                                                        "Weekly",
                                                        "Twice Weekly",
                                                        "Bi-weekly",
                                                        "Monthly",
                                                        "Quarterly",
                                                        "Annually",
                                                    ]}
                                                />
                                            </ul>
                                        </TableDropdown>
                                    </th>
                                    <th>
                                        <TableDropdown headerName="Recipient Email">
                                            <div className="dropdown-menu custom-dropdown-menu px-2">
                                                <SearchInput value={filters.recipientEmail} onChange={(value) => { setFilters({ ...filters, recipientEmail: value }); }} placeholder='Search' />
                                            </div>
                                        </TableDropdown>
                                    </th>
                                    <th className='text-center'>
                                        <TableDropdown headerName="Status">
                                            <ul className="dropdown-menu custom-dropdown-menu px-2">
                                                <TableCheckbox
                                                    selectedItems={filters.status}
                                                    onChangeSelection={(newList) => { setFilters({ ...filters, status: newList }); }}
                                                    listItems={[
                                                        "In Progress",
                                                        "Complete",
                                                        "Failed",
                                                        "Cancelled",
                                                        "Series Cancelled",
                                                    ]}
                                                />
                                            </ul>
                                        </TableDropdown>
                                    </th>
                                    <th>
                                        <TableDropdown headerName="Message">
                                            <div className="dropdown-menu custom-dropdown-menu px-2_5">
                                                <SearchInput isTextarea={true} value={filters.message} onChange={(value) => { setFilters({ ...filters, message: value }); }} placeholder='Search' />
                                            </div>
                                        </TableDropdown>
                                    </th>
                                    <th className="text-center">
                                        <a className="fs-12px text-decoration-none dropdown-link">
                                            Report
                                        </a>
                                    </th>
                                    <th className="text-center">
                                        Action
                                    </th>
                                </tr>}
                                body={packages.map((row, i) => {
                                    return <tr key={i}>
                                        <td className='text-center' style={{ width: 80 }}>
                                            <Checkbox
                                                value={JSON.stringify(row)}
                                                checked={selectedRows.includes(JSON.stringify(row))}
                                                left={20}
                                                onChange={(e) => {
                                                    var isChecked = e.target.checked;
                                                    var exist = selectedRows.findIndex((item) => item == e.target.value)
                                                    var clone = [...selectedRows]
                                                    if (isChecked && exist == -1) clone.push(e.target.value)
                                                    if (!isChecked && exist != -1) clone.splice(exist, 1)
                                                    setSelectedRows(clone)
                                                }}
                                            />
                                        </td>
                                        <td className='text-center w-15'>{moment(row.created_at).format('DD-MM-yyyy HH:mm')}</td>

                                        <td className='w-10'>{row?.distribution?.name || '--'}</td>
                                        <td className='text-center w-10'>{row?.distribution?.method || '--'}</td>
                                        <td className='w-10 text-center'>{row?.distribution?.schedule?.scheduleFrequency || '--'}</td>
                                        <td className='w-10'>{row?.distribution?.emailDraft?.to || '--'}</td>
                                        <td className='text-center w-10'>{row.status}</td>
                                        <td>{row?.distribution?.message || '--'}</td>
                                        <td className="text-center">
                                            <div className={`dropdown`}>
                                                <a
                                                    data-bs-toggle="dropdown"
                                                    className="pointer fs-12px text-decoration-none hide-dropdown-icon dropdown-link"
                                                >
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </a>
                                                <div className="dropdown-menu custom-dropdown-menu px-2_5 report-drpd">
                                                    {row.reportsPDFFiles?.map((report, i) => {
                                                        return <div
                                                            onClick={() => handleDownloadFile(report)}
                                                            className="pointer"
                                                            style={{ textDecoration: 'none', color: "#333333", fontSize: 12 }}
                                                            key={i}
                                                        >
                                                            <div className="d-flex justify-content-between align-item-center mb-2">
                                                                <p className="mb-0">
                                                                    {report.reportName || '--'} 
                                                                </p>
                                                                <div style={{width: 10}}></div>
                                                                <i className="fa fa-download my-auto text-theme"></i>
                                                            </div>
                                                        </div>
                                                    }

                                                    )}
                                                </div>


                                            </div>
                                        </td>
                                        <td className="text-center"> 
                                           {isDeleting == row._id ? <FiLoader color="gray" size={20} className="fa-spin" /> : <AiOutlineDelete onClick={ () => handleDelete(row._id) } className="pointer" size={20} color="red" />}  
                                        </td>
                                    </tr>
                                })}
                            />
                        </>
                    }
                    {packages.length > 0 && <Pagination
                        total={totalPackages}
                        currentPage={currentPage}
                        changeCurrentPageHandler={(page) => {
                            setCurrentPage(page)
                            // loadData(false, limit, page)
                        }}
                        paginationPerPage={limit}
                        changePaginationPerPageHandler={(e) => {
                            setCurrentPage(1)
                            setLimit(e.target.value)
                            // loadData(false, e.target.value, 1)
                        }}
                    />
                    }
                </div>
            </main>
        </Default>
    );
};

export default Packages;