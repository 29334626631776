import React from "react";
import Default from "../../pages/default/Default";

const ErrorPage = (props) => {

    const {
        error = 'Oops! Something went wrong',
        errorMessage = 'please refresh your page and try again',
        image = require('./warning.png'),
        navigateTo = () =>{},
        navigateToText = 'Back to Home',
    } = props;

    return (
        <Default>
            <main className="d-flex flex-column justify-content-center align-items-center py-3">

                <img height={200} width={'auto'} src={image} />

                <h2 class="main-heading">{error}</h2>
                <div className="p-2">
                    <p>
                        {errorMessage}
                    </p>
                </div>

                <div className="p-2">
                    <button onClick={navigateTo} className="btn btn-primary"> {navigateToText} </button>
                </div>

            </main>
        </Default>
    );
};

export default ErrorPage;
