import React from "react";

const ProfileImage = ( {accountName} ) => {



  if(!accountName || accountName == ''){
    return null;
  } 

  const twoChar = accountName.split(' ')

  const styles = {
    icon: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "27px",
      color: "#FFFFFF",
      width: "43px",
      height: "43px",
      background: "#3A2FF2",
      paddingBlock: "8px",
      borderRadius: '50%',
      textAlign: 'center',
    },
  };

  return <div className="me-2" style={styles.icon}>{twoChar[0].replace(/[^a-zA-Z ]/g, "").charAt(0)}{twoChar.length !== 1 ? twoChar[twoChar.length - 1].replace(/[^a-zA-Z ]/g, "").charAt(0).toUpperCase() : ''} </div>;
};

export default ProfileImage;