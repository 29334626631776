import React, { useEffect, useState } from 'react'
import Default from '../default/Default';
import PageTitle from '../../components/UI/PageTitle';
import { SETTINGS_HEADER } from '../../assets/images.config';
import TableTabs from '../../components/UI/table/TableTabs';
import Divider from '../../components/UI/Divider';
import Sizebox from '../../components/UI/Sizebox';
import { SubscriptionsList } from '../subscription/SubscriptionsList';
import Integration from './integration/Integration';
import PostRequest from '../../services/PostRequest';
import { useParams } from 'react-router-dom';

const Settings = () => {

    let { disconnect } = useParams();



    const [activeElement, setActiveElement] = useState(0);
    const [profile, setProfile] = useState(null);

    const handleActiveElement = (id) => {
        setActiveElement(id);
    };


    const loadProfile = () => {
      
        PostRequest('/profile',{})
        .then(profile => {
            setProfile(profile)
        })
        .catch(err => {
            console.log('Error',err)
            // window.location.href = '/app/distributions';
        })

    }

    useEffect(() =>{
        loadProfile();
    },[])


    useEffect(() =>{
        
        if(disconnect == 'QuickBooks-Integration'){
            setActiveElement(1);
        }

    },[disconnect])

    return (
        <Default
            pathHeader="Settings"
            pathLink="/settings"
        >
            <main>
                <PageTitle image={SETTINGS_HEADER} imageClass="background opacity-50" description="Manage and view your billing receipts below." />

                <div className="tableContainer shadow">

                    <Sizebox gap={20} />
                   
                    <TableTabs
                        active={activeElement}
                        handleTabChange={(i) => handleActiveElement(i)}
                        tabs={['Subscriptions','QuickBooks Integration']}
                    />

                    <Divider />

                    {activeElement === 0 && <SubscriptionsList /> }
                    {activeElement === 1 && <Integration profile={profile} /> }

                </div>
            </main>
        </Default>
    );
}

export default Settings