import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION, PROFILE, SEARCH } from "../../assets/images.config";
import './default.css'

import ProfileImage from '../UI/ProfileImage'

const Header = (props) => {

  const navigate = useNavigate();

  const [companyName,setCompanyName] = useState('');
  const [companyEmail,setCompanyEmail] = useState('');


  useEffect(() => {
    setCompanyName(localStorage.getItem('CompanyName') || '')
    setCompanyEmail(localStorage.getItem('CompanyEmail') || '')
  },[])


  const handleNavigation = (link) => {
    navigate(link);
  };


  return (
    <div className="header" >
      <div className="d-flex align-items-center pathBar">
        <Fragment>
          <h1
            className="headingText"
            onClick={() => handleNavigation(props.pathLink || '/')}
          >
            {props.pathHeader || 'Dashboard'}
          </h1>
        </Fragment>
        {props.paths.map((p, i) => {

          return p.hidden ? null : (
            <Fragment key={i}>
              <span className="px-1 pathText">/</span>
              <span
                className="pathText"
                style={props.style}
                onClick={() => handleNavigation(p.link)}
              >
                {p.label}
              </span>
            </Fragment>
          );
        })}
      </div>
      <div className="d-flex align-items-center pe-4_1">
        {/* <img src={SEARCH} className="header-search-icon" alt="img" />
        <img src={NOTIFICATION} className="notificationIcon" alt="" />
        <div className="divider"></div> */}
        <div onClick={() => handleNavigation('/user/profile/edit')} className="d-flex flex-column ps-2_5 pe-3">
          <h3 className="userName pointer">{companyName == undefined ? '' : companyName }</h3>
          <p className="userStatus pointer">{companyEmail}</p>
        </div>
        {/* <img src={PROFILE} alt="" /> */}
        <div className="pointer" onClick={() => handleNavigation('/user/profile/edit')}>
            <ProfileImage accountName={companyName == undefined ? '' : companyName } />
        </div>
      </div>
    </div>
  );
};

export default Header;