import "./buttons.css";
import { BrowserRouter, Route, Routes,Navigate } from "react-router-dom";
import Auth from "./pages/auth/Auth";
import Dashboard from "./pages/dashboard/Dashboard";
import Authentication from "./pages/auth/Authentication";
import "bootstrap/dist/css/bootstrap.min.css";
import Distributions from "./pages/distributions/Distributions";
import "./App.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import CreateDistribution from "./pages/distributions/CreateDistribution";
import Accounts from "./pages/distributions/Accounts";
import Reports from "./pages/distributions/Reports";
import DistributionMethods from "./pages/distributions/DistributionMethods";
import ScheduleDistribution from "./pages/distributions/ScheduleDistribution/ScheduleDistribution";
import ScheduleDistributionEmail from "./pages/distributions/ScheduleDistribution/ScheduleDistributionEmail";
import DraftEmailTemplate from "./pages/distributions/DraftEmailTemplate";
import Finalize from "./pages/distributions/finalize/Finalize";
import FinalizeEmail from "./pages/distributions/finalize/FinalizeEmail";
import Review from "./pages/distributions/review/review";
import ReviewEmail from "./pages/distributions/review/reviewEmail";
import Packages from "./pages/packages/Packages";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import ReportReducer from "./store/report/report.reducer";
import PackageReducer from "./store/package/package.reducer";
import Subscription from "./pages/subscription/Subscription";
import { SubscriptionsList } from "./pages/subscription/SubscriptionsList";
import Subscribe from "./pages/subscription/Subscribe";
import ContactUs from "./pages/other/ContactUs";
import FAQ from "./pages/other/FAQ";
import EditProfile from "./pages/profile/EditProfile";

// GOOGLE ANALITICS
import ReactGA from "react-ga";
import Settings from "./pages/settings/Settings";
import Login from "./pages/auth/Login";
import Disconnect from "./pages/auth/Disconnect";

// const clientSecret = 'pk_test_51N94FLCbheMPrsNjGVs9sQm4rKWbutmZiI5dyJHlwcvYItQWrInVmBP5bs3nNel0ewrDDB9zThhfTrzrTX0yayDR00C4f4n0zR'
const clientSecret = 'pk_live_51NAcNbHyZTsyzW9wgEIYXbushPzhbFlaQMpzSE4reeIwfNnXPpgJxQtSRRpYzNtDZt2xvjCwB8OCXQzZ8zrbbv1000LWpEJFPt'

ReactGA.initialize("G-VV07TN85F0");
ReactGA.pageview(window.location.pathname + window.location.search);

const rootReducer = combineReducers({
  reportReducer: ReportReducer,
  packageReducer: PackageReducer,
});
const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  return (
    
        <Provider store={store}>


          <BrowserRouter basename="/app">
            <Routes>


              <Route
                path="/authentication/:token"
                element={<Authentication />}
              />

            {/* DISCONNECT */}
              <Route
                path="/authentication/:token/:disconnect"
                element={<Authentication />}
              />

              <Route
                path="/authentication/disconnect"
                element={<Disconnect />}
              />

              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate replace to="/distributions" />} />
             
              <Route path="/auth" element={<Auth />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/distributions" element={<Distributions />} />
              <Route
                path="/distributions/create-new"
                element={<CreateDistribution />}
              />
              <Route path="/distributions/accounts" element={<Accounts />} />
              <Route path="/distributions/reports" element={<Reports />} />
              <Route
                path="/distributions/methods"
                element={<DistributionMethods />}
              />
              <Route
                path="/distributions/schedule"
                element={<ScheduleDistribution />}
              />
              <Route
                path="/distributions/schedule-email"
                element={<ScheduleDistributionEmail />}
              />
              <Route
                path="/distributions/draft-email"
                element={<DraftEmailTemplate />}
              />
              <Route path="/distributions/finalize" element={<Finalize />} />
              <Route
                path="/distributions/finalize-email"
                element={<FinalizeEmail />}
              />
              <Route path="/distributions/review" element={<Review />} />
              <Route
                path="/distributions/review-email"
                element={<ReviewEmail />}
              />

              <Route path="/packages" element={<Packages />} />

              {/* subscription */}
              {/* <Route path="/subscriptions" element={<SubscriptionsList />} /> */}
              <Route path="/subscription/renew" element={<Subscription />} />
              <Route path="/subscription/subscribe" element={<Subscribe />} />

              {/* Profile */}
              <Route path="/user/profile/edit" element={<EditProfile />} />

              {/* Settings */}
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/:disconnect" element={<Settings />} />

              {/* OTHER PAGES  */}
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact-us" element={<ContactUs />} />

              <Route path="*" element={<Dashboard />} />
              
            </Routes>
          </BrowserRouter>
        </Provider>
  );
}

export default App;
