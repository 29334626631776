import React, { useEffect } from 'react'
import PostRequest from '../../services/PostRequest';
import { useParams } from 'react-router-dom';

const Authentication = () => {

    const { disconnect = false, token }  = useParams();

    const tryToLogin = async () => {

        try {
            const loginToApp = await PostRequest('/user/sso',{authToken : token});

            console.log('disconnect',disconnect)

            // console.log('loginToApp',loginToApp)
    
            // Save token in localStorage
            localStorage.setItem('QRAuthToken', loginToApp.token);
            localStorage.setItem('CompanyName', loginToApp.companyInfo ? loginToApp.companyInfo?.CompanyName : 'Your Company Name');
            localStorage.setItem('CompanyEmail', loginToApp.email);

            if(disconnect){
                window.location.href = '/app/settings/QuickBooks-Integration';
            } else {
                window.location.href = '/app/distributions';

            }

           
    
        } catch (error) {

            console.log(error)
    
        }

    }

   useEffect(() => {
    tryToLogin();
   },[])

    // if (!authToken) {
    //     return <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
    //         404
    //     </div>
    // }

    return (
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
            Authenticating....
        </div>
    )
}

export default Authentication