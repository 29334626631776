import React, { useState } from "react";
import { SCHEDULE_DISTRIBUTION } from "../../../assets/images.config";
import { useNavigate } from "react-router-dom";
import { timezoneList } from "../../../config/data";
import "../distribution.css";
import moment from 'moment-timezone';


const ScheduleComponent = ({ buttonText, handleSubmit }) => {
    const navigate = useNavigate();

    const [activeScheduleType,setActiveScheduleType] = useState('Once');

    var tz = moment.tz.guess();
     return (

        <>
            <form onSubmit={(e) => {
                let formData = new FormData(e.target);
                e.preventDefault();

                let data = {};

                formData.forEach((val, key) => {
                    data[key] = val;
                });

                if(activeScheduleType == 'Once'){
                    data.endDate = moment(data.startDate, 'yyyy-MM-DD')
                }

                if (activeScheduleType !== 'Once' && moment(data.startDate, 'yyyy-MM-DD').isSameOrAfter(moment(data.endDate, 'yyyy-MM-DD'))) {
                    alert('Please select valid date range')
                    return;
                }

                var old = JSON.parse(localStorage.getItem('distributionData'))
                localStorage.setItem('distributionData', JSON.stringify({
                    ...old,
                    scheduleFrequency: data.frequency,
                    scheduleStartDate: moment(data.startDate, 'yyyy-MM-DD').format('DD-MM-yyyy'),
                    scheduleEndDate: moment(data.endDate, 'yyyy-MM-DD').format('DD-MM-yyyy'),
                    scheduleTime: data.time,
                    scheduleTimezone: data.timeZone,
                }))

                handleSubmit()

            }}>
                <main className="d-flex flex-column align-items-center justify-content-center overflow-auto px-4 mx-4">
                    <h2 className="description">
                        Schedule your Distribution series below.
                    </h2>
                    <div className={`d-flex justify-content-between"`}>
                        <div>
                            <h2 className="frequencyTitle">Distribution Frequency</h2>
                            <div className="frequencies">
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Once') } value="Once" defaultChecked />
                                    <label htmlFor="">Just this once</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Daily') } value="Daily" />
                                    <label htmlFor="">Daily</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Weekly') } value="Weekly" />
                                    <label htmlFor="">Weekly</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Twice Weekly') } value="Twice Weekly" />
                                    <label htmlFor="">Twice Weekly</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Bi-weekly') } value="Bi-weekly" />
                                    <label htmlFor="">Bi-weekly</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Monthly') } value="Monthly" />
                                    <label htmlFor="">Monthly</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Quarterly') } value="Quarterly" />
                                    <label htmlFor="">Quarterly</label>
                                </div>
                                <div className="frequency">
                                    <input type="radio"  name="frequency" onClick={ () => setActiveScheduleType('Annually') } value="Annually" />
                                    <label htmlFor="">Annually</label>
                                </div>
                            </div>
                        </div>
                        <img src={SCHEDULE_DISTRIBUTION} height={250} width={250} alt="" />
                        <div className="scheduleCard">

                            <div className="d-flex">
                                <div className="w-100">
                                    <div className="label">
                                        Start Date <span className="required ms-0">*</span>
                                    </div>
                                    <input type="date" name="startDate" min={moment().format('YYYY-MM-DD')} required />
                                </div>

                                {/* IF NOT JUST ONCE */}
                                {activeScheduleType !== 'Once' && <div>
                                    <div className="label">
                                        End Date <span className="required ms-0">*</span>
                                    </div>
                                    <input type="date" name="endDate" min={moment().format('YYYY-MM-DD')}  required />
                                </div>}
                            </div>
                            <div className={`d-flex timeZoneContainer`}>
                                <div className="w-58">
                                    <div className="label">
                                        Time <span className="required ms-0">*</span>
                                    </div>
                                    <div className="d-flex">
                                        <input type="time" name="time" className="inputTime" required />
                                        {/* <div className="selectedStyle">
                                        <select name="" className="dropdown schedule-dropdown">
                                            <option value="am">am</option>
                                            <option value="pm" defaultValue>
                                                pm
                                            </option>
                                        </select>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="label">
                                        Timezone <span className="required ms-0">*</span>
                                    </div>
                                    <div className="selectedStyle">
                                        <select name="timeZone" className="dropdown schedule-dropdown" defaultValue={tz} >
                                            {timezoneList.map((li, i) => {
                                                return <option value={li} key={i}>{li}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button
                                type='submit'
                                className={`nextButton btn-default border-0 shadow`}
                            >
                                {buttonText}
                            </button>
                        </div>
                    </div>
                </main>
            </form>
            <button
                type="button"
                className={`btn btn-outline-primary mt-3 py-2 btn-previous cancelButton`}
                onClick={() => {
                    navigate("/distributions/methods")
                }}
            >
                PREVIOUS
            </button>
        </>
    );
};

export default ScheduleComponent;
