import React from 'react'
import Default from '../default/Default'

const Dashboard = () => {
    return (
        <Default
            pathHeader="Dashboard"
            pathLink="/"
        >
            <h1 className='pt-5 mt-5 text-center'>Coming Soon!</h1>
           
        </Default>
    )
}

export default Dashboard