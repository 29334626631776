import PostRequest from "../../services/PostRequest";

export const GET_PACKAGES = 'GET_PACKAGES';
export const REMOVE_PACKAGE = 'REMOVE_PACKAGE';

export const getPackages = (data, wantCount) => {

    return async dispatch => {

        try {

            const response = await PostRequest('/packages', data)

            dispatch({
                type: GET_PACKAGES,
                value: response,
                wantCount: wantCount
            })

        } catch (error) {
            return error
        }

    };
}




export const removePackage = (id) => {

    return async dispatch => {

        try {

            dispatch({
                type: REMOVE_PACKAGE,
                value: id,
            })

        } catch (error) {
            return error
        }

    };
}