import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_BG } from "../../assets/images.config";
import { useCallback } from "react";
import Default from "../default/Default";
import PageTitle from "../../components/UI/PageTitle";
import SearchInput from "../../components/UI/SearchInput";
import Button from "../../components/UI/buttons/Button";
import Divider from "../../components/UI/Divider";
import CustomTable from "../../components/UI/table/CustomTable";
import Pagination from "../../components/UI/table/Pagination";
import TableCheckbox from "../../components/UI/table/TableCheckBox";
import TableDropdown from "../../components/UI/table/TableDropDown";
import Checkbox from "../../components/UI/CheckBox";
import "./distribution.css"
import Dropdown from "../../components/UI/DropDown";
// import { reports } from "../../config/data";
import TableTabs from "../../components/UI/table/TableTabs";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "../../store/report/report.action";
import _ from "underscore";

const Reports = () => {

    const _reports = useSelector((state) => state.reportReducer.reports);
    const _totalReports = useSelector((state) => state.reportReducer.totalReports);

    const [loading, setLoading] = useState(false)
    const [isFirstRender, setFirstRender] = useState(true);
    const [activeElement, setActiveElement] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [sort, setSort] = useState('')
    const [reports, setReports] = useState([])

    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleActiveElement = (id) => {
        setActiveElement(id);
    };

    // const handleRowSelected = useCallback((state) => {
    //     setSelectedRows(state.selectedRows);
    // }, []);

    const loadData = async (isFirstCall) => {
        if (loading) return;
        const d = {
            skip: currentPage == 1 ? 0 : limit * (currentPage - 1),
            limit: limit
        }
        if (search != '') d['search'] = search
        try {
            setLoading(true)
            await dispatch(getReports(d))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData(true);
    }, [])

    useEffect(() => {
        setReports(_reports)
    }, [_reports])

    useEffect(() => {
        if (!isFirstRender) {
            loadData(null);
        } else {
            setFirstRender(false)
        }
    }, [search, limit, currentPage])

    useEffect(() => {
        if (sort == 'desc') {
            setReports(_.sortBy(_reports, "label").reverse())
        }
        if (sort == 'asc') {
            setReports(_.sortBy(_reports, "label"))
        }
    }, [sort])

    return (
        <Default
            paths={[
                { label: "Create New", link: "/distributions/create-new" },
                { label: "Reports" }
            ]}
            pathHeader="Distribution"
            pathLink="/distributions"
        >
            <main>

                <PageTitle
                    image={REPORT_BG}
                    description="Select the reports you would like to include in your Distribution."
                />

                <div className="tableContainer shadow">
                    <div className="d-flex justify-content-end ms-auto me-3_5 pt-4 pb-3">
                        <SearchInput placeholder="Search Distribution" value={search} onChange={(value) => setSearch(value)} />
                        <Dropdown className="px-3" buttonText="Filters">
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                        </Dropdown>
                        <Dropdown buttonText="Actions">
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                            <li className="dropdown-item">Lorem ipsum</li>
                        </Dropdown>
                    </div>

                    <TableTabs
                        active={activeElement}
                        handleTabChange={(i) => handleActiveElement(i)}
                        tabs={[' Standard Reports', ' Management Reports', 'Custom Reports']}
                    />

                    <Divider />

                    {activeElement === 0 && <>
                        <CustomTable
                            style={{ height: "calc(100vh - 455px)" }}
                            header={<tr>
                                <th className='text-center'>
                                    <TableDropdown headerName="Select">
                                        <ul className="dropdown-menu custom-dropdown-menu px-2">
                                            <TableCheckbox
                                                listItems={["Select All"]}
                                            />
                                        </ul>
                                    </TableDropdown>
                                </th>
                                <th className={`${sort == 'desc' ? 'sortable-desc' : 'sortable'}`} onClick={() => setSort(sort == 'asc' || sort == '' ? 'desc' : 'asc')}>Report Name</th>
                                <th className="sortable text-center">Created By</th>
                                <th className="sortable text-center">Report Period</th>
                                <th className="sortable">Notes</th>
                                <th className="sortable text-center">Last Modified</th>
                            </tr>}
                            body={reports.map((row, i) => {
                                return <tr key={i}>
                                    <td className='text-center' style={{ width: 80 }}>
                                        <Checkbox
                                            value={JSON.stringify(row)}
                                            checked={selectedRows.includes(JSON.stringify(row))}
                                            left={18}
                                            onChange={(e) => {
                                                var isChecked = e.target.checked;
                                                var exist = selectedRows.findIndex((item) => item == e.target.value)
                                                var clone = [...selectedRows]
                                                if (isChecked && exist == -1) clone.push(e.target.value)
                                                if (!isChecked && exist != -1) clone.splice(exist, 1)
                                                setSelectedRows(clone)
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <span style={{ color: "#3A4DFD", cursor: "pointer" }}>
                                            {row.label}
                                        </span>
                                    </td>
                                    <td className="text-center">{row.createdBy || 'QuickBooks'}</td>
                                    <td className="text-center">{row.reportPeriod || '--'}</td>
                                    <td className="w-25"><div className="white-space-normal">{row.notes || '--'}</div></td>
                                    <td className="text-center">
                                        {row.lastModifiedTime || '--'}
                                        {/* <div>
                                            <p className="lastModifiedTime">{row.lastModifiedTime || '--'}</p>
                                            <h3 className="lastModifiedDate">{row.lastModifiedDate || '--'}</h3>
                                        </div> */}
                                    </td>
                                </tr>
                            })}
                        />
                        <Pagination
                            total={_totalReports}
                            currentPage={currentPage}
                            changeCurrentPageHandler={(page) => setCurrentPage(page)}
                            paginationPerPage={limit}
                            changePaginationPerPageHandler={(e) => {
                                setCurrentPage(1)
                                setLimit(e.target.value)
                            }}
                        />
                    </>}

                </div>
            </main>
            {selectedRows.length > 0 && (
                <Button
                    className={`nextButton btn-default shadow`}
                    onClick={() => {
                        var old = JSON.parse(localStorage.getItem('distributionData'))
                        localStorage.setItem('distributionData', JSON.stringify({ ...old, reports: selectedRows }))
                        navigate("/distributions/methods")
                    }}
                >
                    NEXT
                </Button>
            )}

            <button
                className="btn btn-outline-primary py-2 btn-previous"
                onClick={() => navigate("/distributions/accounts")}
            >
                PREVIOUS
            </button>
        </Default>
    );
};

export default Reports;
