import React from 'react';

import "./UIStyles.css"

const PageTitle = (props) => {
    return (
        <div className="appPageTitle">
            <div className="d-flex align-items-center">
                <img src={props.image} className={`page-title-bg ${props.imageClass}`} alt="" />
                <h4 className={`page-title-description ${props.descriptionClass}`}>
                    {props.description}
                </h4>
            </div>
        </div>
    )
}

export default PageTitle